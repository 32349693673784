import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { clearAllFormValues } from 'app/+state/actions/form-values.action';
import {
  clearPriceOffers,
  clearPriceOffersLoadingCount,
  loadNewPriceOffers,
} from 'app/+state/actions/price-offer.actions';
import { StoreFacade } from 'app/+state/store.facade';
import { CallMeOfferComponent } from 'app/components/call-me-offer/call-me-offer.component';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { processFieldValueForServer } from '../../forms/process-field-value-for-server';
import { FieldDto } from '../../models/dto/field-dto';
import {
  CategoriesService,
  DeviceService,
  ProductService,
  ScrollService,
} from '../../services';
import { ContractPreviewService } from '../../services/contract-preview.service';
import { FirstStepService } from '../../services/first-step.service';
import { InsurenceService } from '../../services/insurence.service';
import { LayoutService } from '../../services/layout.service';
import { OffersService } from '../../services/offers.service';
import { SavedOfferService } from '../../services/saved-offer.service';
import { ThirdStepService } from '../../services/third-step.service';

declare var fbq: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  /* subscriptions */
  productCategorySub: Subscription;
  insurenceSub: Subscription;
  paramSub: Subscription;

  product: any = null;
  currentStep = 1;
  validatedSteps: Array<number> = [];
  stepOverviews: any = {};
  chosenOffer: any;
  loading = true;
  isTestServer = false;

  constructor(
    private storeFacade: StoreFacade,
    private categoriesService: CategoriesService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private scrollService: ScrollService,
    private firstStepService: FirstStepService,
    private thirdStepService: ThirdStepService,
    private savedOfferService: SavedOfferService,
    private offersService: OffersService,
    private layoutService: LayoutService,
    private contractPreviewService: ContractPreviewService,
    private insurenceService: InsurenceService,
    private router: Router,
    private dialog: MatDialog,
    private deviceService: DeviceService,
    private http: HttpClient,
    private gtag: Gtag
  ) {
    /* for testing */
    if (
      window.location.hostname.indexOf('test.poistuj') > -1 ||
      window.location.hostname.indexOf('localhost') > -1
    ) {
      this.isTestServer = true;
    }

    /* set CSS class for body */
    layoutService.setBodyClasses(['product-detail']);

    /* setting the product + scrolling to step 1 */
    this.productCategorySub = combineLatest(
      this.productService.productChange,
      this.categoriesService.categoriesChange
    ).subscribe(([product, categories]) => {
      if (product && categories && categories.length > 0) {
        this.loading = false;
        this.product = {
          ...product,
          category: categories.find((c) => c.id === product.categoryId),
        };
        window.location.hash = 'step1';
        if (this.deviceService.isMobile()) {
          setTimeout(() => {
            this.scrollService.scrollToElementById('stepHash1');
          }, 200);
        }
      }
    });

    /* creating Insurence - if ERROR, return to previous steps */
    this.insurenceSub = this.insurenceService.insurenceErrorsChange.subscribe(
      (errors) => {
        if (Array.isArray(errors)) {
          if (errors.length > 0) {
            if (errors[0] === '500') {
              this.currentStep = 4;
              this.productService.setCurrentStep(4);
              this.scrollService.scrollToElementById('stepHash4');
              this.insurenceService.cleanErrors();
            } else {
              this.currentStep = 3;
              this.productService.setCurrentStep(3);
              this.scrollService.scrollToElementById('stepHash3');
              this.insurenceService.cleanErrors();
            }
          }
        }
      }
    );

    /* on before unload */
    const component = this;
    window.onbeforeunload = function (e: BeforeUnloadEvent) {
      if (
        (component.currentStep > 1 ||
          component.firstStepService.hasSomeUserData()) &&
        component.currentStep < 5
      ) {
        e = e || window.event;
        // For IE and Firefox prior to version 4
        if (e) {
          e.returnValue = 'Naozaj chcete opustiť stránku?';
        }
        // For Safari
        return 'Naozaj chcete opustiť stránku?';
      } else {
        return null;
      }
    };
  }

  /* NG ON INIT */
  ngOnInit() {
    /* PAREMETER CHANGE PROCESSING */
    this.paramSub = this.route.paramMap
      .pipe(
        map((params: ParamMap) => ({
          id: params.get('id'),
          savedOfferNumber: params.get('savedOfferNumber'),
          extra: params.get('extra'),
        }))
      )
      .subscribe((params: any) => {
        if (params.savedOfferNumber) {
          this.savedOfferService.loadSavedOffer(
            params.savedOfferNumber,
            true,
            params.extra === 's65d4'
          );
        } else {
          this.savedOfferService.noSavedOfferLoading();
        }
        this.product = null;
        this.loading = true;
        const id = +params.id;
        this.currentStep = 1;
        // this.firstStepService.cleanForms();
        this.savedOfferService.clear();
        this.insurenceService.clear();
        this.contractPreviewService.clear();
        // this.thirdStepService.cleanForms();
        this.storeFacade.dispatch(clearPriceOffers());
        this.storeFacade.dispatch(clearAllFormValues());
        this.productService.load(id);
        window.location.hash = 'step1';
      });
  }

  goToStep(stepNumber: number) {
    if (this.currentStep < 5 || this.isTestServer) {
      window.location.hash = 'step' + stepNumber;

      this.currentStep = stepNumber;
      this.productService.setCurrentStep(stepNumber);

      if (stepNumber < 5) {
        this.insurenceService.clear();
      }

      if (stepNumber < 4) {
        this.contractPreviewService.clear();
      }

      if (stepNumber < 3) {
        // this.thirdStepService.cleanForms();
      }

      if (stepNumber < 2) {
        this.storeFacade.dispatch(clearPriceOffers());
      }
    }
  }

  isStepDone() {
    return false;
  }

  offerChosen(offer: any) {
    if (offer.price === 0) {
      const dialogRef = this.dialog.open(CallMeOfferComponent, {
        data: {
          savedOfferId: this.savedOfferService.getSavedOffer()['id'],
          insCompanyTitle: offer.title,
        },
      });
    } else {
      this.chosenOffer = offer;
      this.offersService.choseOffer(offer);
      this.currentStep = 3;
      window.location.hash = 'step3';
      this.productService.setCurrentStep(3);
      setTimeout(() => {
        //  this.validatedSteps.push(2);
        setTimeout(() => {
          this.scrollService.scrollToElementById('stepHash3');
        }, 0);
        // this.stepOverviews[2] = this.firstStepService.getOverview(this.product);
      }, 0);
    }
  }

  fourthStepSubmitted() {
    //  console.log('******** fourthStepSubmitted *********');

    this.currentStep = 5;
    window.location.hash = 'step5';
    this.productService.setCurrentStep(5);
    setTimeout(() => {
      this.scrollService.scrollToElementById('stepHash5');
    }, 0);

    combineLatest(
      this.storeFacade.formValues$,
      this.storeFacade.firstFormFieldsAsArray$,
      this.storeFacade.thirdStepfields$,
      this.storeFacade.extrafields$
    )
      .pipe(take(1))
      .subscribe(
        ([formValues, firstStepFields, thirdStepFields, extraFields]) => {
          const values = {};
          const formlyFields = this.firstStepService.getFormlyFields();
          firstStepFields.forEach((field: FieldDto) => {
            const value = processFieldValueForServer(
              field,
              formValues[field.code],
              formlyFields
            );
            if (value !== '') {
              values[field.id] = value;
            }
          });
          const formlyFieldsThirdStep = this.thirdStepService.getFormlyFields();
          thirdStepFields.forEach((field: FieldDto) => {
            const value = processFieldValueForServer(
              field,
              formValues[field.code],
              formlyFieldsThirdStep
            );
            if (value !== '') {
              values[field.id] = value;
            }
          });
          extraFields.forEach((field: FieldDto) => {
            const value = processFieldValueForServer(
              field,
              formValues[field.code],
              []
            );
            if (value !== '') {
              values[field.id] = value;
            }
          });

          const savedOfferId = this.savedOfferService.getSavedOffer();
          const contractForm = values;
          const chosenOffer = this.offersService.getChosenOffer();
          const contractPreview = this.contractPreviewService.getAsObject();

          this.insurenceService.create(
            savedOfferId,
            contractForm,
            chosenOffer,
            contractPreview
          );
        }
      );
  }

  thirdStepSubmitted() {
    this.currentStep = 4;
    window.location.hash = 'step4';
    this.productService.setCurrentStep(4);
    this.validatedSteps.push(3);
    setTimeout(() => {
      this.scrollService.scrollToElementById('stepHash4');
    }, 0);
    this.thirdStepService.logResults();
    this.contractPreviewService.load();
    // this.stepOverviews[3] = this.firstStepService.getOverview(this.product);
  }

  /* FIRST STEP SUBMITTED */
  firstStepSubmitted() {
    this.currentStep = 2;
    window.location.hash = 'step2';
    this.productService.setCurrentStep(2);
    this.validatedSteps.push(1);
    setTimeout(() => {
      this.scrollService.scrollToElementById('stepHash2');
    }, 0);
    this.stepOverviews[1] = this.firstStepService.getOverview(this.product);

    this.storeFacade.dispatch(loadNewPriceOffers({ product: this.product }));
    fbq('track', 'Lead');

    setTimeout(() => {
      this.storeFacade.dispatch(clearPriceOffersLoadingCount());
    }, 30000);

    if (!(window.location.hostname.indexOf('test.poistuj') > -1)) {
      this.gtag.event('conversion', {
        send_to: 'AW-655748312/m_slCMOm1N8BENjZ17gC',
      });
    }

    /*this.offersService.clearOffers();
    this.offersService.loadApiOffers(
      this.product,
      this.categoriesService.getCategory(this.product.categoryId),
      this.firstStepService.getAsObject()
    );
    this.offersService.loadServerOffers(
      this.product.id,
      this.firstStepService.getFieldsDataForApi()
    );*/
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
    this.productCategorySub.unsubscribe();
    this.insurenceSub.unsubscribe();
    this.storeFacade.dispatch(clearPriceOffers());
    this.storeFacade.dispatch(clearAllFormValues());
  }
}
