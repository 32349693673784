import {Action, createReducer, on} from '@ngrx/store';

import {loadAutocompleteItemsFinished} from '../actions/poistuj.actions';

export interface AutocompleteItemsState {
  [itemsCode: string]: any;
}

const initialState = {};

const autocompleteItemsReducer = createReducer(
  initialState,
  on(loadAutocompleteItemsFinished, (state, action) => {
    const newState = { ...state };
    newState[action.itemsCode] = action.items;
    return newState;
  })
);

export function autocompleteItemsReducerFunction(
  state: AutocompleteItemsState | undefined,
  action: Action
) {
  return autocompleteItemsReducer(state, action);
}
