import { createReducer, Action, on } from '@ngrx/store';
import {FieldDto} from '../../models/dto/field-dto';
import { NewFormFieldValue } from '../../models/new-form-field-value';
import {
  setFormFieldValues,
  setFormValue,
  setFormValues,
  clearAllFormValues
} from '../actions/form-values.action';
import { state } from '@angular/animations';
import {setFirstStepFields, setThirdStepFieldsDefaultValue} from '../actions/poistuj.actions';

export interface FormValuesState {
  [key: string]: any;
}

const initialState = {};

const formValuesReducer = createReducer(
  initialState,
  on(setFormValue, (state, { fieldCode, value }) => {
    const newState = { ...state };
    newState[fieldCode] = value;
    return newState;
  }),
  on(clearAllFormValues, () => {
    return initialState;
  }),
  on(setFormValues, (state, action) => {
    return {
      ...state,
      ...action.formValues
    };
  }),
  on(setFormFieldValues, (state, action) => {
    const newState = { ...state };
    action.newFieldValues.forEach((newFieldValue: NewFormFieldValue) => {
      newState[newFieldValue.fieldCode] = newFieldValue.value;
    });
    return newState;
  }),
    on(setFirstStepFields, (state, action) => {
      const newState = { ...state };
      action.fields.forEach((field: FieldDto) => {
        if (field.defaultValue) {
          newState[field.code] = field.defaultValue;
        }
      });
      return newState;
    }),
    on(setThirdStepFieldsDefaultValue, (state, action) => {
      const newState = { ...state };
      action.defaultValueFields.forEach((field: { code: string, value: string }) => {
        newState[field.code] = field.value;        
      });
      return newState;
  })
);

export function formValuesReducerFunction(
  state: FormValuesState | undefined,
  action: Action
) {
  return formValuesReducer(state, action);
}
