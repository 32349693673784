import {Action, createReducer, on} from '@ngrx/store';

import {setExtraFields} from '../actions/poistuj.actions';
import {FirstStepFieldsState} from './first-step-fields.reducer';
import { clearPriceOffers } from '../actions/price-offer.actions';

const initialState: FirstStepFieldsState = {
    fields: []
};

const extraFieldsReducer = createReducer(
    initialState,
    on(setExtraFields, (state, { fields }) => {
        return {
            ...state,
            fields
        };
    }),
    on(clearPriceOffers, (_state, _action) => {
        return initialState;
    }),
);

export function extraFieldsReducerFunction(
    state: FirstStepFieldsState | undefined,
    action: Action
) {
    return extraFieldsReducer(state, action);
}
