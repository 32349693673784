import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutService {
    private bodyClasses: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
    bodyClassesChange: Observable<Array<string>>;

    constructor() {
        this.bodyClassesChange = this.bodyClasses.asObservable();
    }

    setBodyClasses(classes: Array<string>) {
        this.emitBodyClasses(classes);
    }

    private emitBodyClasses(bodyClasses):void {
        document.body.className = bodyClasses.join(' ');
        this.bodyClasses.next(bodyClasses);
    }
}