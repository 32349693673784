<div class="close-modal" (click)="close()"><img src="assets/images/x.svg"></div>

<h3 style="text-align: center;" [innerHTML]="title"></h3>

<div class="category-products">
  <ng-container *ngFor="let product of products">
      <div *ngIf="product.slug" class="product-card" (click)="navigate(product)">
          <div class="card-image"><img src="assets/{{ product.iconBlack }}"></div>
          <div class="card-image card-image-hover"><img src="assets/{{ product.iconWhite }}"></div>
          <div class="card-label">{{ product.title }}</div>
      </div>
  </ng-container>
</div>