import {createSelector} from '@ngrx/store';

import {State} from '../reducers';
import {FormValuesState} from '../reducers/form-values.reducer';

export const selectFormValues = (state: State) => state.formValues;

export const selectFormFieldValue = (fieldId: string) => createSelector(selectFormValues, (state: FormValuesState) => {
	return state[fieldId];
});
