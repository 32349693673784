import { Injectable } from '@angular/core';
import { CanDeactivate,  CanActivate,   ActivatedRouteSnapshot,   RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { ProductService } from '../services/product.service';
import { FirstStepService } from '../services/first-step.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmChangesDialogComponent } from '../components/confirm-changes-dialog/confirm-changes-dialog.component';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfirmChangesGuardGuard implements CanDeactivate<any> {  
  constructor(
    private productService: ProductService,
    private firstStepService: FirstStepService,
    public dialog: MatDialog
  ) {

  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    if ((this.productService.currentStep>1 || this.firstStepService.hasSomeUserData()) && this.productService.currentStep<5) {
      let dialogRef = this.dialog.open(ConfirmChangesDialogComponent, {});
      return dialogRef.afterClosed().pipe(map(result=>{
        return result;
      }));
    } else {
      return true;
    }
  }
}
