import {Action, createReducer, on} from '@ngrx/store';

import {FieldDto} from '../../models/dto/field-dto';
import {setFirstStepFields} from '../actions/poistuj.actions';

export interface FirstStepFieldsState {
  fields: FieldDto[];
}

const initialState: FirstStepFieldsState = {
  fields: []
};

const firstStepFieldsReducer = createReducer(
  initialState,
  on(setFirstStepFields, (state, { fields }) => {
    return {
      ...state,
      fields
    };
  })
);

export function firstStepFieldsReducerFunction(
  state: FirstStepFieldsState | undefined,
  action: Action
) {
  return firstStepFieldsReducer(state, action);
}
