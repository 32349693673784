import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiService } from 'app/services';
import { ModalInfoComponent } from 'app/components/modal-info/modal-info.component';

@Component({
  selector: 'app-call-me-offer',
  templateUrl: './call-me-offer.component.html',
  styleUrls: ['./call-me-offer.component.scss']
})
export class CallMeOfferComponent implements OnInit {
  callMe:any = {
    phone: '',
    when: 'dnes'
  };

  loading: boolean = false;
  error: boolean = false;
  success: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CallMeOfferComponent>,
    private api: ApiService
  ) {

  }

  ngOnInit() {
  }

  callMeSubmitted() {
    this.loading = true;
    this.api.post('/call-me-offer', {
      phone: '+421 '+this.callMe.phone,
      //when: this.callMe.when,
      offer: this.data.savedOfferId,
      insCompanyTitle: this.data.insCompanyTitle
    })
      .subscribe(result=>{
        //console.log('call-me-offer result: ', result);    
        this.callMe = {
          phone: '',
          when: 'dnes'
        };
        this.loading = false;    
        this.success = true;
        /*let dialogRef = this.dialog.open(ModalInfoComponent, {
            data: {
              message: 'Ďakujeme, ozveme sa.'
            }
        });  */
      });
  }

  finished() {
    this.loading = false;
  }

  errorFromServer() {
    this.loading = false;
    this.error = true;
  }

  close() {
    this.dialogRef.close();
  }


}
