<div *ngIf="!loading && !success && !error">
  <h2 class="text-center">Volajte <strong>0948 910 910</strong></h2>
  <p class="text-center">Po - Pi od 9:00 do 16:00</p>

  <hr/>

  <p class="text-center">alebo nám zanechajte číslo a my Vám zavoláme naspäť:</p>

  <form class="call-me-form" (ngSubmit)="callMeSubmitted()" #callMeForm="ngForm" style="padding-bottom: 60px;">

      <div class="row">

          <div class="col-md-8 col-md-offset-2">

              <mat-form-field>
                  <span matPrefix>+421 &nbsp;</span>
                  <input #phoneRef="ngModel" minlength="9" required (keyup.enter)="callMe()" name="phone" [(ngModel)]="callMe.phone" matInput placeholder="Telefónne číslo">
                  <mat-error *ngIf="phoneRef?.errors?.required">Políčko je povinné</mat-error>
              </mat-form-field>

          </div>

          <!--<div class="col-md-4">

              <mat-form-field>
                  <mat-select #whenRef="ngModel" required name="when" [(ngModel)]="callMe.when">
                      <mat-option value="dnes">
                          volať dnes
                      </mat-option>
                      <mat-option value="zajtra">
                          volať zajtra
                      </mat-option>
                      <mat-option value="piatok">
                          volať v piatok
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="whenRef?.errors?.required">Políčko je povinné</mat-error>
              </mat-form-field>

          </div>-->

          <div class="col-md-12 clear-float">

              <div class="button-row text-center"> 
                  <button [disabled]="!callMeForm.valid" class="mat-raised mat-primary mat-button mat-ink-ripple" type="submit" mat-raised-button>Zavolajte mi</button>
              </div>

          </div>

      </div>

  <!--

      <div class="form-error" *ngIf="apiErrors.length > 0">
          Pri spracovaní Vašich údajov sa objavili nasledovné chyby:<br/>
          <p *ngFor="let error of apiErrors">{{ error }}</p>
      </div>

      <div class="form-submit-button">
        <button class="mat-raised mat-primary mat-button mat-ink-ripple" type="submit" mat-raised-button>Potvrdiť údaje</button>
      </div>

  -->

  </form>
</div>

<app-loader *ngIf="loading" [text]="text"></app-loader>

<div *ngIf="!loading && success">
  <h3 *ngIf="!error">Ďakujeme. Ozveme sa čo najskôr.</h3>
  <h3 *ngIf="error">Mrzí nás to, ale nastala chyba a číslo k nám nebolo doručené.</h3>
  <mat-dialog-actions>
    <div class="text-center">
      <button (click)="close()" mat-raised-button class="mat-raised mat-primary mat-button mat-ink-ripple">Zavrieť</button>
    </div>
  </mat-dialog-actions>
</div>