import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';

import {setFormFieldValues} from '../+state/actions/form-values.action';
import {StoreFacade} from '../+state/store.facade';
import {mockFOThirdStep} from '../../example-data/step-3/fo';
import {AutocompleteItemsEnum} from '../models/autocomplete-items.enum';
import {ApiService} from './api.service';
import {FieldsConditionsService} from './fields-conditions.service';
import {FieldsProcessorService} from './fields-processor.service';
import {FirstStepService} from './first-step.service';

@Injectable()
export class ThirdStepService {
  private forms: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  formsChange: Observable<any>;

  formGroup: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
  formGroupChange: Observable<FormGroup>;

  extraFields: any = {};

  default: any = {};
  formValues: any = {};
  autocompleteItems: any = {};

  chosenOffer: { id: number, motherInsCompanyId: number };
  formlyFields: any;

  constructor(
    private apiService: ApiService,
    private fieldsProcessorService: FieldsProcessorService,
    private fieldsConditionsService: FieldsConditionsService,
    private firstStepService: FirstStepService,
    private storeFacade: StoreFacade
  ) {
    this.formsChange = this.forms.asObservable();
    this.formGroupChange = this.formGroup.asObservable();
    this.storeFacade.formValues$.subscribe(
      (formValues: any) => (this.formValues = formValues)
    );
    this.storeFacade.allAutocompleteItems$.subscribe(
      (autocompleteitems: any) => (this.autocompleteItems = autocompleteitems)
    );
  }

  getFormlyFields() {
    return this.formlyFields;
  }

  setFormlyFields(formlyFields) {
    this.formlyFields = formlyFields;
  }

  loadExampleData() {
    const mock = mockFOThirdStep;
    this.storeFacade.dispatch(
      setFormFieldValues({
        newFieldValues: mock.map(item => ({
          fieldCode: item.code,
          value: item.value
        }))
      })
    );
  }

  logResults() {}


  cleanForms() {
    this.formGroup.next(null);
    this.forms.next([]);
    this.extraFields = {};
  }

  /*setExtraFields(extraFields) {
    this.extraFields = extraFields;
  }*/

  getOverview() {
    let result = '';
    const items = this.autocompleteItems;
    const cityId = this.formValues['city-residence'];
    let theCity = '';
    if (cityId) {
      const city: any = Object.values(items[AutocompleteItemsEnum.City]).filter(
          (item: any) => item.id === cityId
      );
      if (city.length > 0) {
        theCity = city[0].title;
      }
    }

    let individualKind = 'fo';
    const ownerKind = this.formValues['vehicle-holder-kind'];

    if (ownerKind) {
      // vehicle-owner-kind
      if (ownerKind === 'Právnická osoba') {
        individualKind = 'po';
      }
      if (ownerKind === 'Fyzická osoba podnikateľ') {
        individualKind = 'fop';
      }
    }

    if (individualKind === 'fo') {
      result +=
        this.fieldValue('title-before-name') +
        ' ' +
        this.fieldValue('name') +
        ' ' +
        this.fieldValue('surname') +
        ' ' +
        this.fieldValue('title-after-name') +
        ', ' +
        this.fieldValue('street-residence') +
        ' ' +
        this.fieldValue('house-number') +
        ', ' +
        this.fieldValue('zip-code-residence') +
        ' ' +
        theCity;
    }

    if (individualKind === 'po') {
      result +=
        this.fieldValue('vehicle-insur-company-name-po') +
        ', ' +
        this.fieldValue('street-residence') +
        ' ' +
        this.fieldValue('house-number') +
        ', ' +
        this.fieldValue('zip-code-residence') +
        ' ' +
        theCity;
    }

    if (individualKind === 'fop') {
      result +=
        this.fieldValue('vehicle-insur-company-name-fop') +
        ', ' +
        this.fieldValue('vehicle-insur-id-number-fop') +
        ', ' +
        this.fieldValue('street-residence') +
        ' ' +
        this.fieldValue('house-number') +
        ', ' +
        this.fieldValue('zip-code-residence') +
        ' ' +
        theCity;
    }
    return result;
  }

  fieldValue(code: string) {
    if (this.formValues[code]) {
      return this.formValues[code];
    } else {
      return '';
    }
  }

  getForms() {
    return this.forms.getValue();
  }

  getChosenOffer() {
    return this.chosenOffer;
  }

  loadContractForms(offer) {
    return this.apiService
      .get('/getproductcontractforms/' + offer.id)
      .subscribe(data => {
        this.chosenOffer = data.offer;
        this.setForms(data.forms);
      });
  }

  loadValues() {
    for (let form of this.getForms()) {
      for (let field of form.fields) {
        if (this.default.hasOwnProperty(field.id)) {
          field.value = this.default[field.id];
        }
      }
    }
  }

  processForms(rawForms, offer): FormGroup {
    const currentForms = this.forms.getValue();

    let formObject = {};
    let forms = this.fieldsProcessorService.processForms(
      rawForms,
      formObject,
      null,
      offer
    );

    this.setForms(forms);
    let formGroup = new FormGroup(formObject);

    const secondStepExtraFields: {
      [key: string]: string;
    } = this.firstStepService.getSecondStepExtraFields();

    for (let form of forms) {
      for (let field of form.fields) {
        // set field value from first step form
        const firstField = this.firstStepService.getFieldByCode(field.code);
        if (firstField) {
          field.value = firstField.value;
        }

        if (secondStepExtraFields.hasOwnProperty(field.id)) {
          field.disabled = true;
          field.value = secondStepExtraFields[field.id];
        }

        if (this.extraFields[field.id]) {
          field.value = this.extraFields[field.id];
        }

        if (field.code == 'zip-code-residence') {
          const firstField = this.firstStepService.getFieldByCode('zip-code');
          if (firstField) {
            field.value = firstField.value;
          }
        }
        if (field.code == 'city-residence') {
          const firstField = this.firstStepService.getFieldByCode(
            'vehicle-city'
          );
          if (firstField) {
            field.value = firstField.value;
          }
        }
      }
    }

    let currentFieldValues = {};
    for (let form of currentForms) {
      for (let field of form.fields) {
        currentFieldValues[field.id] = field.value;
      }
    }

    for (let form of forms) {
      for (let field of form.fields) {
        if (currentFieldValues.hasOwnProperty(field.id) && !field.value) {
          field.value = currentFieldValues[field.id];
        }
      }
    }

    for (let form of this.firstStepService.getForms()) {
      for (let field of form.fields) {
        this.fieldValueChanged(field);
      }
    }

    for (let form of forms) {
      for (let field of form.fields) {
        this.fieldValueChanged(field);
      }
    }

    return formGroup;
  }

  setForms(forms) {
    this.forms.next(forms);
  }

  fieldValueChanged(field) {
    this.default[field.id] = field.value;
    this.setForms(
      this.fieldsConditionsService.updateFormsByField(
        field,
        this.forms.getValue(),
        this.formGroup
      )
    );
  }
}
