import {Injectable} from '@angular/core';

import {AutocompleteItemsEnum} from '../models/autocomplete-items.enum';
import {ApiService} from './api.service';

@Injectable()
export class AutocompleteApiService {
  constructor(private apiService: ApiService) {}

  getItems(recordType: AutocompleteItemsEnum) {
    return this.apiService.get('/autocompleteitems/' + recordType);
  }
}
