<div *ngIf="category$|async as category">
    <div class="products-banner" [ngStyle]="{backgroundImage:'linear-gradient(rgba(0, 0, 0, 0.40),rgba(0, 0, 0, 0.40)),url('+category.titleBackground+')'}">
        <div class="container">
            <h1>{{ category.title }}</h1>
            <h3 [innerHTML]="category.pageTitle"></h3>

            <div class="category-products">
                <ng-container *ngFor="let product of category.products">
                    <a *ngIf="product.slug" class="product-card" [routerLink]="['/p', product.slug , product.id]">
                        <div class="card-image"><img src="assets/{{ product.iconBlack }}"></div>
                        <div class="card-image card-image-hover"><img src="assets/{{ product.iconWhite }}"></div>
                        <div class="card-label">{{ product.title }}</div>
                    </a>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="category-content" [innerHTML]="category.sanitizedContent">
        <!--<div class="container">
            <div class="full-info">
                <h2>Poistenie auta online, bez čakania. Ušetrite až 30€!</h2>
                <p>Cez Poistuj.sk porovnáte ceny PZP poistenia auta, motorky, štvorkolky a vy si vyberiete to najvhodnejšie alebo to najlacnejšie povinné zmluvné poistenie.Kvalitné PZP poistenie auta, motorky, štvorkolky si cez poistuj.sk určite uzatvoríte vždy vrátane všetkých zliav a bonusov.</p>
            </div>
            <div class="two-cols">
                <div class="half-col text-col">
                    <h4>Čo je povinné zmluvné poistenie?</h4>
                    <p>
                        Povinné zmluvné poistenie, je poistenie zodpovednosti za škodu spôsobenú prevádzkou motorového vozidla, ktoré je stanovené zákonom 381/2001 a držiteľ vozidla je povinný uzatvoriť toto poistenie pred uvedením vozidla do prevádzky. "Poistené musia byť všetky motorové vozidlá jazdiace na pozemných komunikaciach."
                    </p>
                    <div class="info-box">
                        <div class="info-icon"><img src="assets/images/alert.svg"></div>
                        <p class="info-text">Pokutu až do výšky 3319,39 € môže dostať každý držiteľ vozidla, ak PZP uzatvorené nemá.</p>
                    </div>
                    <p>Povinné zmluvné poistenie si na poistuj.sk dojednáte bez papierov a podpisovania sám z pohodlia domova alebo zo svojho mobilného telefónu.</p>
                    <p><strong>Nenechajte nič na náhodu a <a href="#">založte si PZP</a> už teraz.</strong></p>
                    <p>V prípade akýchkoľvek otázok ohľadom poistenia nás neváhajte kontaktovať.</p>
                </div>
                <div class="half-col">
                    <div class="grey-box">
                        <h4>Výhody uzatvorenia PZP na Poistuj.sk</h4>
                        <div class="tick-list">
                            <p><img src="assets/images/check.svg">Získavate OKAMŽITE VŠETKY BONUSY A VERNOSTNÉ ZĽAVY, na ktoré máte nárok.</p>
                            <p><img src="assets/images/check.svg">Žiadne NÁKLADY NA NÁVŠTEVU poisťovne.</p>
                            <p><img src="assets/images/check.svg">Bezkonkurenčná výhoda v podobe uzatvorenia poistenia "odkiaľkoľvek".</p>
                            <p><img src="assets/images/check.svg">Jednoducho a prehľadne spracované ponuky.</p>
                            <p><img src="assets/images/check.svg">Ponuky iba overených poisťovateľov.</p>
                            <p><img src="assets/images/check.svg">Vždy aktuálna výška poistného.</p>
                            <p><img src="assets/images/check.svg">Bez spoluúčasti.</p>
                            <p><img src="assets/images/check.svg">Zjednodušené dojednanie poistnej zmluvy.</p>
                            <p><img src="assets/images/check.svg">BEZPEČNÁ platba vždy na účet poisťovne.</p>                       
                        </div>
                    </div>
                </div>
            </div>
            <div class="full-info only-info-title">
                <h2>Poistenie cez Poistuj.sk je jednoduhšie a pohodlnejšie</h2>
            </div>
            <div class="two-cols">
                <div class="half-col">
                    <h4>Len 5 krokov Vás delí od uzavretia poistenia:</h4>
                    <div class="numbered-list">
                        <div class="item">
                            <div class="order-icon">1.</div>
                            <div class="item-content">
                                Zadáte základné údaje pre ponuku poistenia. Obvykle ide o základné informácie o vozidle, začiatku poistenia, teda dátum, od ktorého požadujete účinnosť vášho poistenia. Pravdivo musíte uviesť informácie o držiteľovi poisťovaného vozidla.
                            </div>
                        </div>
                        <div class="item">
                            <div class="order-icon">2.</div>
                            <div class="item-content">
                                Vyberiete si najvhodnejšiu alebo najlacnejšiu ponuku PZP poistenia, ktoré Vám poistuj.sk zobrazí na základe Vami zadaných základných údajov.
                            </div>
                        </div>
                        <div class="item">
                            <div class="order-icon">3.</div>
                            <div class="item-content">
                                Vyplníte doplňujúce údaje a informácie pre Vaše poistenie.
                            </div>
                        </div>
                        <div class="item">
                            <div class="order-icon">4.</div>
                            <div class="item-content">
                                Skontrolujete si zadané údaje a potvrdíte predloženie návrhu.
                            </div>
                        </div>
                        <div class="item">
                            <div class="order-icon">5.</div>
                            <div class="item-content">
                                    Poistenie máte hotové, ešte ostáva zaplatiť poistné podľa platobných údajov, ktoré sú Vám automaticky vygenerováne a dostupné pre tlač, súčasne sú Vám všetky dokumenty doručené do Vami uvedenej e-mailovej schránky.                                    
                            </div>
                        </div>
                    </div>
                    <div class="home-button">
                        <button (click)="showProductsModal(category.pageTitle)">Založiť poistenie</button>
                    </div>
                </div>
                <div class="half-col pop-image-col">
                    <div class="pop-image">
                        <div class="image"><img src="assets/images/accident-car-communication-2224.jpg"></div>
                        <h4><img src="assets/images/alert2.svg">&nbsp;&nbsp;Upozornenie</h4>
                        <p>Vaše poistenie je platne až po zaplatení dohodnutého poistného. Doklady o poistení sú platné iba za predpokladu, že poistné bolo zaplatené a pripísané na účet poisťovne, ktorú ste si zvolili.</p>
                    </div>
                </div>
            </div>
            <div class="two-cols">
                <h4>Nezabudnite</h4>
                <div class="half-col left">                    
                    <div class="dotted-list with-margin">
                        <div class="item">
                            <div class="order-dot"></div>
                            <div class="item-content">
                                Uzatvoriť si najlacnejšie PZP online je veľmi jednoduché. Stačí vyplniť niekoľko informácií o Vás a Vašom vozidle v jednoduchom formulári a obratom získate prehľad ponúk PZP a v priebehu pár minút máte poistenie hotové.
                            </div>
                        </div>
                        <div class="item">
                            <div class="order-dot"></div>
                            <div class="item-content">
                                Doklad o PZP tzv. biela karta, ktorá je Vám k dispozícií OKAMŽITE po potvrdení poistnej zmluvy. Je tiež zaslaná na Vami určený mail spolu s ostatnými tlačivami, teda máte doklad vždy k dispozícií. Tento doklad je vždy dočasný. Akonáhle PZP zaplatíte, obdržíte bezplatne doklad o PZP poštou priamo od poisťovne so štandardnou platnosťou.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="half-col">
                    <div class="dotted-list">
                        <div class="item no-bottom-margin">
                            <div class="order-dot"></div>
                            <div class="item-content">
                                Online PZP je uzatvorené na diaľku v súlade s právnym poriadkom, ktorý ustanovuje občianský zákonník. To znamená, že návrh zmluvy, ktorý Vám zašleme emailom NIKDY NEMUSÍTE PODPISOVAŤ. Akceptujete ho tak, že zaplatíte dohodnuté poistné vždy na účet zvolenej poisťovne. ALE POZOR: poistné musí byť zaplatené v splatnosti uvedenej na zmluve.
                            </div>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="info-icon"><img src="assets/images/alert.svg"></div>
                        <p class="info-text">Ak uzatvárate PZP na už poistené vozidlo nezabudnite vypovedať Vaše predchádzajúce PZP. Spôsob výpovede sa riadi zákonom. Vzor výpovede nájdete <a target="_blank" href="https://admin.poistuj.sk/uploads/assets/2018/4/vypoved_poistenia.pdf">TU</a>.</p>
                    </div>
                </div>
            </div>
            <div class="two-cols">
                <h4>Na čo dať najväčší pozor pri uzatváraní PZP?</h4>
                <div class="half-col other-info left">                    
                    <h5 class="no-top-margin">Najlacnejšie nie je najvhodnejšie</h5>
                    <p>Aplikácia poisťuj.sk zoradzuje ponuky od najlacnejšej po najdrahšiu. Odporúčame ale podrobne preštudovať aj drahšie ponuky, nakoľko k PZP ponúkajú poisťovne rad krytia „navyše“ ako benefit, alebo pripoistenia si môžete dojednať. Veľmi obľúbené je poistenie čelných skiel, stret so zverou, krytie živelných škôd alebo jednoduché poistenie auta proti vlastnej škode prípadne odcudzeniu. Máte teda možnosť za zlomok ceny získať aj 1/3 havarijneho poistenia pre Vaše vozidlo.</p>
                    <h5>Dávať pozor na cestách a jazdiť zodpovedne JE NEVYHNUTNÉ</h5>
                    <p>Výšku poistného Vášho PZP ovplyvňuje najmä Výkon a objem motora, Váš vek a bydlisko ale predovšetkym naviac to, ako ste v minulosti zodpovedne jazdil vozidlom. Preto „nepomáhajte ani kamarátom s poškodeným čelným sklom“, môžete stratiť všetky získané bonusy.</p>
                </div>

                <div class="half-col other-info">
                    <h5>Limity poistného plnenia</h5>
                    <p>Pri dojednaní PZP sú dôležité limity poistného plnenia. Minimálna výška limitu pre PZP je 5 240 000 eur (pre škody na zdraví a náklady pri usmrtení na každého zraněného alebo usmrteného) a 1 050 000 eur ( pre škody spôsobené na majetku a ušlý zisk prevšetkých poškodených spolu). Toto sú maximálne sumy, ktoré pri Vami spôsobenej škode vyplatí poisťovňa tretej strane, ktorej ste spôsobili škodu. Doporučujeme vyberať vyššie limity poistného krytia. Ak chcete byť dobre krytí, vyberajte si vyššie limity. Rad poisťovní ponúka limity pre PZP vo výške 2.5, 7 alebo aj 10 miliónov EUR.</p>
                </div>
            </div>
            <div class="big-box">
                <h2>Najlacnejšie poistenie auta už od 40 €</h2>
                <p>Cez Poistuj.sk ušetrí väčšina držiteľov vozidiel so zľavou <strong>až do výšky 70%</strong></p>
                <div class="home-button">
                    <button (click)="showProductsModal(category.pageTitle)">Založiť poistenie</button>
                </div>
            </div>
        </div>-->
    </div>
</div>