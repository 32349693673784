import { ProductComponent, HomepageComponent } from './pages';
import { InsurencePageComponent } from './pages/insurence-page/insurence-page.component';
import { AboutComponent } from './pages/static/about/about.component';
import { ConfirmChangesGuardGuard } from './guards/confirm-changes-guard.guard';
import { DocumentPageComponent } from './pages/document-page/document-page.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SafetyComponent } from './pages/safety/safety.component';
import { ContactComponent } from 'app/pages/contact/contact.component';
import { CategoryComponent } from 'app/pages/category/category.component';

export const routes = [
  { path: '', component: HomepageComponent, canDeactivate: [] },
  { path: 'p/:slug/:id', component: ProductComponent, canDeactivate: [ConfirmChangesGuardGuard] },
  //{ path: 'product/:id', component: ProductComponent, canDeactivate: [ConfirmChangesGuardGuard] },
  { path: 'p/:slug/:id/:savedOfferNumber', component: ProductComponent, canDeactivate: [ConfirmChangesGuardGuard] },
  { path: 'p/:slug/:id/:savedOfferNumber/:extra', component: ProductComponent, canDeactivate: [ConfirmChangesGuardGuard] },
  { path: 'kat/:slug', component: CategoryComponent },
  { path: 'poistovna/:id', component: InsurencePageComponent, canDeactivate: [] },
  //{ path: 'o-nas', component: AboutComponent, canDeactivate: [ConfirmChangesGuardGuard] },
  { path: 'o-nas', component: DocumentPageComponent, data: { documentId: 179 } },
  { path: 'obchodne-podmienky', component: DocumentPageComponent, data: { documentId: 1 } },
  { path: 'ochrana-osobnych-udajov', component: DocumentPageComponent, data: { documentId: 4 } },
  { path: 'informacie-pre-klienta', component: DocumentPageComponent, data: { documentId: 3 } },
  { path: 'cookies', component: DocumentPageComponent, data: { documentId: 9 } },
  //{ path: 'bezpecnost', component: SafetyComponent },
  { path: 'bezpecnost', component: DocumentPageComponent, data: { documentId: 180 } },
  { path: 'faq', component: DocumentPageComponent, data: { documentId: 181 } },
  { path: '404', component: NotFoundComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'zakladne-informacie-o-pzp', component: DocumentPageComponent, data: { documentId: 169 } },
  { path: 'zakladne-informacie-o-cestovnom-poisteni', component: DocumentPageComponent, data: { documentId: 170 } },
  { path: 'zakladne-informacie-o-havarijnom-poisteni', component: DocumentPageComponent, data: { documentId: 171 } },
  { path: 'zakladne-informacie-o-urazovom-poisteni', component: DocumentPageComponent, data: { documentId: 172 } },
  { path: 'zakladne-informacie-o-zodpovednosti-za-skodu', component: DocumentPageComponent, data: { documentId: 173 } },
  { path: 's/:documentId/:extra', component: DocumentPageComponent, data: { documentId: 0, class: 'subweb' } },
  { path: '**', redirectTo: '404' },
];
