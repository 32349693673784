import { Injectable } from '@angular/core'; 
import { Observable, BehaviorSubject } from 'rxjs';

const DEVICE_DESKTOP = 'DESKTOP';
const DEVICE_MOBILE = 'MOBILE';

@Injectable()
export class DeviceService {
    private currentDevice: BehaviorSubject<any> = new BehaviorSubject(DEVICE_DESKTOP);
    deviceChange: Observable<any>;

    constructor(
    ) {
        this.deviceChange = this.currentDevice.asObservable();
        if (window.innerWidth>1000) {                                
            this.currentDevice.next(DEVICE_DESKTOP);
        } else {            
            this.currentDevice.next(DEVICE_MOBILE);
        }
    }

    device() {        
        if (window.innerWidth>1000) {                                
            return DEVICE_DESKTOP;
        } else {            
            return DEVICE_MOBILE;
        }
    }

    isDesktop() {
        return this.currentDevice.value==DEVICE_DESKTOP;
    }

    isMobile() {
        return this.currentDevice.value==DEVICE_MOBILE;
    }
}
