import * as moment from 'moment';

import {FieldDto, ValueTypeEnum} from '../models/dto/field-dto';

export function processFieldValueForServer(field: FieldDto, value: any, formlyFields:any) {
  let hiddenByCondition = false;

  formlyFields.forEach((f) => {
    f.fieldGroup.forEach((fieldGroup) => {
      if (fieldGroup.key === field.code && fieldGroup.templateOptions.hiddenByCondition) {
        hiddenByCondition = true;
      }
    });
  });

  if (hiddenByCondition) {
    return field.defaultValue || '';
  }

  if (value === null || value === 'null' || value === '') {
    return '';
  }

  if (field.valueType === ValueTypeEnum.date) {
    const m = moment(value);
    return {
      day: m.date().toString(),
      month: (m.month() + 1).toString(),
      year: m.year().toString()
    };
  }

  if (field.valueType === ValueTypeEnum.calendar) {
    const m = moment(value);
    const now = moment();
    if (field.options === 'future-from-today' && m.isBefore(now)) {
      return now;
    }
    const tomorrow = now.add(1, 'days');
    if (field.options === 'future' && m.isBefore(tomorrow)) {
      return tomorrow;
    }
  }

  if (field.valueType === ValueTypeEnum.spz) {
    return {
      region: value.substr(0, 2),
      number: value.substr(2)
    };
  }
  return value;
}
