
<div class="static-page-content">
    

    <h3>Ďakujeme Vám za návštevu</h3>

    <p>
    Ak ste prišli zistiť o čom poistuj.sk je a čo sa skrýva za farebným logom „ulity“,  ste na správnom mieste. Cieľom poistuj.sk je byť nezávislým zdrojom informácií a umožniť každému uzatvoriť si poistenie za tú najlepšiu cenu. Denne uzatvárame rôzne typy poistenia pre našich klientov od popredných poisťovní na trhu. 
    </p>

    <p class="text-center">
„     ... poistku???<br/>
www.poistuj.sk „
    </p>

    <p class="text-center about-logo">
        <img src="assets/images/logo-big.png">
    </p>


    <h3>Hľadáte poistenie?</h3>

    <p>
    Už ste niekedy použili Poistuj.sk? Ak nie, prezrite si webstránku www.poistuj.sk a zistite, ako Vám dokážeme pomôcť nájsť a uzatvoriť to najvhodnejšie poistenie. Tým, že spolupracujeme s poprednými poisťovňami a spájame ich informácie o produktoch poistenia na jedno miesto, šetríme Váš čas a peniaze.
    </p>


    <h3>Náš názor</h3>
    <p>
        Ľudia potrebujú základné a prehľadné informácie o poistení. Jeho jednoduchú dostupnosť bez zbytočného navštevovania pobočiek poisťovní, ponúkania produktov poistenia o ktoré nemajú záujem a zvyšovania si nákladov.
    </p>
    <p>
        Prostredníctvom Poistuj.sk si klient uzatvorí poistenie, <strong>ktoré potrebuje s okamžitou platnosťou.</strong> 
    </p>
    <p class="text-center">
        <strong><i>Na www.poistuj.sk máte "Jednoducho a pohodlne" svoje poistenie vždy dostupné.</i></strong>
    </p>

    <h3>Právne informácie</h3>
    <p>
        <strong>Názov spoločnosti:</strong> AVAM FINANCIAL GROUP s.r.o.<br/>
        <strong>Sídlo:</strong> Sad SNP 14, 010 01 Žilina, Slovensko<br/>
        <strong>Tel.:</strong> 0948 910 910, 0948 920 920<br/>
        <strong>Zapísaná v obchodnom registri:</strong> Okresného súdu Žilina, oddiel: Sro, Vložka číslo: 18568/L        <br/>
        <strong>Spoločnosť zastúpená:</strong> Vladimírom Pajonkom - konateľom<br/>
        <strong>IČO:</strong> 36 732 796<br/>
        <strong>DIČ:</strong> SK 202 232 8473
    </p>

    <h3>Prevádzkovateľ webstránok:</h3>
    <p>
    AVAM FINANCIAL GROUP s.r.o., <br/>
    Sad SNP 14, 010 01 Žilina, Slovensko
    <br/>
    <br/>
    <br/>
    </p>

    <!--<h2>Kariéra:</h2>

    <h3>Filozofia a firemné hodnoty</h3>-->

    <h3>TRADÍCIA A SPOĽAHLIVOSŤ</h3>
    <p>Pracujeme zodpovedne, svedomite, starostlivo a spoľahlivo. Od roku 2007 spolupracujeme a udržiavame vzťahy s poprednými poisťovňami na trhu. Pričom ide o dlhodobý proces, pri ktorom sme splnili požiadavky, zastupovať tieto poisťovne, ktoré svojim obrazom a filozofiou zodpovedajú našim hodnotám.</p>

    <h3>INDIVIDUÁLNY PRÍSTUP</h3>
    <p>Jediným dôvodom tejto skutočnosti je presvedčenie, že našim záväzkom voči klientom, ale i samotným poisťovniam, je sústrediť celu energiu spolupracovníkov a najmä vedenie firmy iba pre Poistuj.sk. Toto cielené smerovanie našej činnosti má jasne preukázateľný a pozitívny vplyv na úroveň poskytovaných služieb. </p>

    <h3>KLIENT</h3>
    <p>Hlavné nosné hodnoty sú v prístupe ku klientovi. Otvorený a prirodzený vzťah, na ktorý kladieme veľký dôraz prináša a udržiava spokoných a stálych klientov.</p>

    <h3>TÝM A UPRÍMNOSŤ</h3>
    <p>Tvoríme pohodové pracovné prostredie, fungujúci kolektív a jednáme férovo.</p>

    <h3>DESIGN A KOMFORT POISŤOVANIA</h3>
    <p>
        Webstránka www.poistuj.sk prešla rozsiahlou inováciou, spĺňa najnovšie štandarty a kritéria na trhu online poistenia. Sme preto hrdý, že Vám môžeme poskytnúť jedinečný spôsob uzatvárania poistenia bez podpisovania zmlúv „JEDNODUCHO A POHODLNE“
    </p>
</div>