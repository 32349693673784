import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {mockFOFirstStep} from 'example-data/step-1/fo';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';

import {setFormFieldValues} from '../+state/actions/form-values.action';
import {AutocompleteItemsState} from '../+state/reducers/autocomplete-items.reducer';
import {StoreFacade} from '../+state/store.facade';
import {mockFOPFirstStep} from '../../example-data/step-1/fop';
import {mockPOFirstStep} from '../../example-data/step-1/po';
import {AutocompleteItemsEnum} from '../models/autocomplete-items.enum';
import {Product} from '../models/product';
import {AutocompleteItemsService} from './autocomplete-items.service';
import {FieldsConditionsService} from './fields-conditions.service';
import {FieldsProcessorService} from './fields-processor.service';
import {SavedOfferService} from './saved-offer.service';

@Injectable()
export class FirstStepService {
  private forms: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  formsChange: Observable<any>;
  formGroup: FormGroup;

  extraFields: {[key: string]: string} = {};

  formValues: any;
  autocompleteItems: AutocompleteItemsState;

  formlyFields;

  constructor(
    private fieldsProcessorService: FieldsProcessorService,
    private fieldsConditionsService: FieldsConditionsService,
    private savedOfferService: SavedOfferService,
    private autocompleteItemsService: AutocompleteItemsService,
    private storeFacade: StoreFacade
  ) {
    this.formGroup = new FormGroup({});
    this.formsChange = this.forms.asObservable();
    this.storeFacade.formValues$.subscribe((formValues: any) => this.formValues = formValues);
    this.storeFacade.allAutocompleteItems$.subscribe((autocompleteitems: any) => this.autocompleteItems = autocompleteitems);
  }

  getFormlyFields() {
    return this.formlyFields;
  }

  setFormlyFields(formlyFields) {
    this.formlyFields = formlyFields;
  }

  cleanForms() {
    //this.forms.next([]);
    //this.extraFields = {};
    this.formGroup = new FormGroup({});
  }

  setFormData(formData) {
    const existingFields = [];

    for (let form of this.forms.getValue()) {
      for (let field of form.fields) {
        if (formData.hasOwnProperty(field.id)) {
          existingFields.push(field.id);
        }
        if (field.value !== formData[field.id]) {
          field.value = formData[field.id];
        }
      }
    }

  }

  cleanSecondStepExtraField() {
    this.extraFields = {};
  }

  getSecondStepExtraFields() {
    return this.extraFields;
  }

  setSecondStepFields(fields: {[key: string]: string}) {
    this.extraFields = fields;
  }

  getAsObject() {
    let final = {};
    this.forms.getValue().forEach(form=>{
      form.fields.forEach(field => {
        final[field.id] = field.value;
      });
    });

    return final;
  }

  getForms() {
    return this.forms.getValue();
  }

  getFieldsDataForApi() {
    let data = {};
    this.forms.getValue().forEach(form=>{
      form.fields.forEach(field => {
        data[field.id] = field.value;
      });
    });
    return data;
  }

  setForms(forms) {
    this.forms.next(forms);
  }

  processForms(rawForms) {
    /*this.fieldsConditionsService.clearConditionsTreet();
    let formObject = {};
    let savedOfferFormData = this.savedOfferService.getFirstFormData();
    let forms = this.fieldsProcessorService.processForms(rawForms, formObject, savedOfferFormData, null);
    this.setForms(forms);
    //this.formGroup = new FormGroup(formObject);
    this.formGroup = new FormGroup(formObject);

    for (let form of forms) {
      for (let field of form.fields) {
        this.fieldValueChanged(field);
      }
    }

    return this.formGroup;*/

  }

  hasSomeUserData() {
    return this.formGroup && this.formGroup.dirty;
  }

  fieldValueChanged(field) {
    if (field.fieldRules) {
      field.fieldRules.forEach(fieldRule => {
        if (fieldRule.ruleType === 'onchange') {
          if (typeof this[fieldRule.ruleValue] === 'function') {
            this[fieldRule.ruleValue](field);
          }
        }
      });
    }
    this.setForms(this.fieldsConditionsService.updateFormsByField(field, this.forms.getValue(), this.formGroup));
  }

  getOverview(product: Product) {
    let result = '';

    if (product.id === 1) {

        // PZP AUTO
        const items = this.autocompleteItems;

        const vehicleBrandFieldId = this.formValues['vehicle-brand'];
        const vehicleBrands: any = Object.values(items[AutocompleteItemsEnum.VehicleBrand]).filter((item:any) => item.id === vehicleBrandFieldId);

        const vehicleModelFieldId = this.formValues['vehicle-sales-name'];
        const vehicleModels: any = Object.values(items[AutocompleteItemsEnum.VehicleSalesName]).filter((item:any) => item.id === vehicleModelFieldId);

        const insurenceStartDate = moment(this.formValues['start-of-insurence']);

        result += vehicleBrands[0].title + ' ' + vehicleModels[0].title
         + ', ' +
        this.formValues['vehicle-production-year'] + ', ' +
        this.formValues['engine-capacity'] + 'cm<sup>3</sup>, ' +
        this.formValues['engine-power'] + 'kW, ' +
        this.formValues['fuel-type'] + ', ' +
        this.formValues['vehicle-weight'] + 'kg, ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';

    }

    if (product.id == 2) {
      // PZP MOTORKA
      const insurenceStartDate = moment(this.formValues['start-of-insurence']);
      result += this.formValues['engine-capacity'] + 'cm<sup>3</sup>, ' +
        this.formValues['engine-power'] + 'kW, ' +
        this.formValues['fuel-type-moto'] + ', ' +
        this.formValues['vehicle-weight'] + 'kg, ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 3) {
      // PZP STVORKOLKA
      let insurenceStartDate = moment(this.formValues['start-of-insurence']);
      result += this.formValues['engine-capacity'] + 'cm<sup>3</sup>, ' +
        this.formValues['engine-power'] + 'kW, ' +
        this.formValues['vehicle-weight'] + 'kg, ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 4) {
      // PZP PRIVES
      let insurenceStartDate = moment(this.formValues['start-of-insurence']);
      result +=
        this.formValues['vehicle-weight'] + 'kg, ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 5) {
      // PZP TRAKTOR
      let insurenceStartDate = moment(this.formValues['start-of-insurence']);
      result += this.formValues['vehicle-holder-kind'] + ', ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 6) {
      // PZP NAKLADNE AUTO
      let insurenceStartDate = moment(this.formValues['start-of-insurence']);
      result +=
        this.formValues['vehicle-weight'] + 'kg, ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 16) {
      // ZODP NEVYROBNA
      let insurenceStartDate = moment(this.formValues['start-of-insurence-spol']);
      result += this.formValues['liab-insur-limit'] + ', ' +
        this.formValues['liab-job-tittle-officer'] + ', ' +
        this.formValues['liab-insurance-area'] + ', ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 17) {
      // ZODP VYROBNA
      let insurenceStartDate = moment(this.formValues['start-of-insurence-spol']);
      result += this.formValues['liab-insur-limit'] + ', ' +
        this.formValues['liab-job-tittle-worker'] + ', ' +
        this.formValues['liab-insurance-area'] + ', ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 18) {
      // ZODP VODIC
      let insurenceStartDate = moment(this.formValues['start-of-insurence-spol']);
      result += this.formValues['liab-insur-limit'] + ', ' +
        this.formValues['liab-job-tittle-driver'] + ', ' +
        this.formValues['liab-insurance-area'] + ', ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 12) {
      // URAZ OSOBNE VOZIDLO
      let insurenceStartDate = moment(this.formValues['start-of-insurence-spol']);
      //result += this.formValues['vehicle-holder-kind'] + ', ' +
      result += 'počet miest na sedenie: ' + this.formValues['car-seats-count'] + ', ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 13) {
      // URAZ DODAVKA
      let insurenceStartDate = moment(this.formValues['start-of-insurence-spol']);
      result +=
        'počet miest na sedenie: ' + this.formValues['car-seats-count'] + ', ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    if (product.id == 15) {
      // URAZ NAKLADNE
      let insurenceStartDate = moment(this.formValues['start-of-insurence-spol']);
      result +=
        'počet miest na sedenie: ' + this.formValues['car-seats-count'] + ', ' +
        this.formValues['vehicle-weight'] + 'kg, ' +
        'začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }

    /*if (product.id == 8 || product.id == 9 || product.id == 10) {
        var insurenceStartDate = this.formGroup.get('start-of-insurence').value;
        result += this.formGroup.get('vehicle-holder-kind').value + ', havarijné poistenie v '+this.formGroup.get('casco.insurer').value + ', začiatok poistenia: <strong>' + insurenceStartDate.toDate().getDate() + '. ' + (insurenceStartDate.toDate().getMonth()+1) + '. ' + insurenceStartDate.toDate().getFullYear() + '</strong>';
    }*/

    //console.log('result: '+result);

    return result;
  }

  getFieldByCode(fieldCode) {
    let final = null;
    for (let form of this.forms.getValue()) {
      for (let field of form.fields) {
        if (field.code == fieldCode) {
          final = field;
        }
      }
    }
    return final;
  }

  setFieldValueByCode(fieldCode, value) {
    for (let form of this.forms.getValue()) {
      for (let field of form.fields) {
        if (field.code == fieldCode) {
          field.value = value;
        }
      }
    }
  }

  // 'fo', 'fop', 'po'
  loadExampleData(code: string) {
    const mock = code === 'fo' ? mockFOFirstStep : (code === 'fop' ? mockFOPFirstStep : mockPOFirstStep);
    this.storeFacade.dispatch(setFormFieldValues({
      newFieldValues: mock.map(item => ({
        fieldCode: item.code,
        value: item.value
      }))
    }));
  }

}
