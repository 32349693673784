import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';

import {ProductDetailDto} from '../models/dto/product-detail-dto';
import {ApiService} from './api.service';
import {ModalNotifyService} from './modal-notify.service';

@Injectable()
export class ProductService {
  private product: BehaviorSubject<ProductDetailDto> = new BehaviorSubject(null);
  productChange: Observable<any>;
  currentStep = 1;

  constructor(
    private apiService: ApiService,
    private modalNotifyService: ModalNotifyService,
    private router: Router
  ) {
    this.productChange = this.product.asObservable();
  }

  getCurrentStep() {
    return this.currentStep;
  }

  setCurrentStep(step: number) {
    this.currentStep = step;
  }

  load(id: number): void {
      this.apiService.get('/getproductpagedetails/' + id)
                  .pipe(take(1))
                  .subscribe(
                    product => this.setProduct(product),
                    error => {
                      this.router.navigate(['']);
                      this.modalNotifyService.showSimpleInfo('Požadovaný produkt nie je možné poistiť.');
                    });
  }

  setProduct(product: ProductDetailDto) {
    this.product.next(product);
  }

}
