<div class="home-banner">
    <div class="container">
        <h1>Vyklikajte si vlastné poistenie za pár minút</h1>
        <h3>Je to jednoduché. Vybriete si poistenie, ktoré chcete uzatvoriť, vyplníte požadované údaje a poistenie si uzavriete do pár minút.</h3>
        <div class="home-categories">
            <a class="category-card pzp" [href]="FORMULARE_FE_URL">
                <p>Chcem uzatvoriť</p>
                <h3>Povinné zmluvné poistenie</h3>
                <div class="image">
                    <img src="assets/images/pzp-img.png">
                </div>
            </a>
<!--            <a class="category-card" routerLink="/kat/zodpovednost">-->
<!--                <p>Chcem uzatvoriť</p>-->
<!--                <h3>Zodpovednosť zamestnanca</h3>-->
<!--                <div class="image">-->
<!--                    <img src="assets/images/zodpovednost-zam-img.png">-->
<!--                </div>-->
<!--            </a>-->
<!--            <a class="category-card uraz" routerLink="/kat/sedadla">-->
<!--                <p>Chcem uzatvoriť</p>-->
<!--                <h3>Poistenie úrazu vo vozidle</h3>-->
<!--                <div class="image">-->
<!--                    <img src="assets/images/poistenie-urazu-img.png">-->
<!--                </div>-->
<!--            </a>-->
            <!--<a class="category-card ee" routerLink="/kat/ee">
                <p>Chcem uzatvoriť</p>
                <h3>Asistenčné služby</h3>
                <div class="image">
                    <img src="assets/images/asist-sluzby-img.png">
                </div>
            </a>-->
        </div>
    </div>
</div>

<div class="container home-container">
    <h2>Prečo sa poistiť cez Poistuj.sk?</h2>
    <div class="why-options">
        <div class="why-option">
            <div class="image">
                <img src="assets/images/time.svg">
            </div>
            <h3>Šetríte čas</h3>
            <ul>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Dostupné kdekoľvek</li>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;S okamžitou platnosťou</li>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Za pár minút máte v šetko v emaily</li>
            </ul>
        </div>

        <div class="why-option">
            <div class="image">
                <img src="assets/images/savings.svg">
            </div>
            <h3>Šetríte peniaze</h3>
            <ul>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Vrátane akčných zliav</li>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Všetko online bez papierov</li>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Platba priamo na účet poisťovne</li>
            </ul>
        </div>

        <div class="why-option">
            <div class="image">
                <img src="assets/images/overview.svg">
            </div>
            <h3>Získate prehľad</h3>
            <ul>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Prehľadné porovnanie</li>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Bez skrytých podmienok</li>
                <li><img src="assets/images/check.svg">&nbsp;&nbsp;&nbsp;Vždy dostupná kalkulácia</li>
            </ul>
        </div>
    </div>
    <div class="home-button">
        <button (click)="showCategoriesModal()">Založiť poistenie</button>
    </div>
</div>

<div class="home-containers-steps">
    <div class="container home-container">
        <h2>5 krokov k poisteniu</h2>
        <div class="home-steps-new">
            <div class="hs">
                <h3>1.</h3>
                <p>Vyplníte základné údaje pre ponuku.</p>
            </div>
            <div class="ha">
                <img src="assets/images/chevron-right.svg">
            </div>
            <div class="hs">
                <h3>2.</h3>
                <p>Vyberiete si najvhodnejšiu ponuku.</p>
            </div>
            <div class="ha">
                <img src="assets/images/chevron-right.svg">
            </div>
            <div class="hs">
                <h3>3.</h3>
                <p>Zadáte doplňujúce údaje a informácie.</p>
            </div>
            <div class="ha">
                <img src="assets/images/chevron-right.svg">
            </div>
            <div class="hs">
                <h3>4.</h3>
                <p>Skontrolujete a potvrdíte Vaše údaje.</p>
            </div>
            <div class="ha">
                <img src="assets/images/chevron-right.svg">
            </div>
            <div class="hs">
                <h3>5.</h3>
                <p>Zaplatíte a Vaša zmluva je platná.</p>
            </div>
        </div>
        <div class="home-button">
            <button (click)="showCategoriesModal()">Založiť poistenie</button>
        </div>
    </div>
</div>

<div class="home-containers-saved-offer">
    <div class="container home-container">
        <div class="left">
            <h2>Načítajte ponuku</h2>
            <p>Načítajte si Vami vytvorenú ponuku zadaním čísla ponuky. Rýchlo a jednoducho!</p>
            <div class="saved-offer-field">
                <input [(ngModel)]="savedOfferNumber" value="" placeholder="Zadajte číslo ponuky" (keyup.enter)="loadSavedOffer()">
                <button (click)="loadSavedOffer()" [disabled]="!savedOfferNumber" [class.active]="!!savedOfferNumber">Načítať ponuku</button>
            </div>
        </div>
        <div class="right">
            <div class="img"><img src="assets/images/ponuka-img.png"></div>
        </div>
    </div>
</div>
