<div class="static-page-content">
    
       


    <h3 class="text-center">Bezpečnostný SSL certifikát</h3>

    <!--<p class="text-center about-logo">
        <img src="assets/images/letsencrypt-logo-horizontal.svg">
    </p>-->

    <p>
    Webstránka Poistuj.sk je prevádzkovaná s vysokým stupňom šifrovania. 
    Nakoľko pracujeme s osobnými údajmi klientov, snažíme sa neustále zabezpečiť bezpečnú dátovú 
    komunikáciu medzi klientom a našou webstránkou. 
    Bezpečnostný certifikát je tak základnym kameňom bezpečnosti. 
    Dátova komunikácia je zabezpečená prostredníctvom certifikátu Let's Encrypt Authority X3, 
    ktorý všetky citlivé informácie šifruje algoritmom sha256. 
    Ako dôkaz zabezpečenia webstránky Poistuj.sk slúží prítomnosť 
    sieťového protokolu HTTPS počas uzatvárania poistenia.
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </p>   
  
    <h3 class="text-center">Slovenská obchodná inšpekcia</h3>

    <!--<p class="text-center about-logo">
        <img src="assets/images/soi-logo-sk.gif">
    </p>-->

    <p>
    Činnosť našej spoločnosti ako finančného agenta spadá tiež pod kontrolu Slovenskej obchodnej inšpekcie. Ide o orgán dozoru vo veci ochrane spotrebiteľa v zmysle zákona o štátnej kontrole. Dozorom našej spoločnosti sa zaoberá Inšpektorát SOI pre Žilinský kraj, Odbor výkonu dozoru, Predmestská 71, P. O. BOX B-89, 011 79 Žilina 1, Tel. 041/763 21 30, 041/724 58 68, http://www.soi.sk/
      <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </p> 

    <h3 class="text-center">Povolenie Národnej banky Slovenska</h3>

    <p>
    Naša spoločnosť je zapísaná v Registri finančných agentov a finančných poradcov pod číslom 138494. Na základe Rozhodnutia číslo ODT-2974-1/2012 Národnej banky Slovenska sme oprávnení vykonávať finančné sprostredkovanie v oblasti poistenia alebo zaistenia.
    </p>
    <p>
    Poistenie uzatvorené prostredníctvom našej WEBaplikácie tak spĺňa podmienky platných zákonov Slovenskej republiky.
      <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </p> 

</div>
