import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';

import { fillSpzRegionField } from '../../forms/onchange/fill-spz-region-field';
import {poznateSpzChange} from '../../forms/onchange/poznate-spz-change';
import { setAgeOfOwnerField } from '../../forms/onchange/set-age-of-owner-field';
import { setClaimFields } from '../../forms/onchange/set-claim-fields';
import { spzApiLoadValues } from '../../forms/onchange/spz-api-load-values';
import { ApiService, AutocompleteApiService } from '../../services';
import {
  onChangeFunctionNOTFOUND,
  runOnChangeFunction
} from '../actions/form-values.action';
import {
  loadAutocompleteItems,
  loadAutocompleteItemsFinished
} from '../actions/poistuj.actions';
import { State } from '../reducers';

@Injectable()
export class AppEffects {
  loadAutocompleteItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAutocompleteItems),
      mergeMap(action =>
        this.autocompleteItemsService.getItems(action.itemsCode).pipe(
          map(items =>
            loadAutocompleteItemsFinished({
              itemsCode: action.itemsCode,
              items: items
            })
          )
        )
      )
    )
  );

  runOnChangeFunction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(runOnChangeFunction),
            withLatestFrom(this.store$),
            map(([action, state]) => {
                switch (action.functionName) {
                    case 'fillSpzRegionField()':
                        return fillSpzRegionField(state);
                    case 'setAgeOfOwnerField()':
                        return setAgeOfOwnerField(state);
                    case 'poznateSpzChange()':
                        return poznateSpzChange(state);
                    case 'setClaimFields':
                        return setClaimFields(state);
                    /*case 'spzApiLoadValues':
                        return spzApiLoadValues(state, this.apiService);*/
                    default:
                        return onChangeFunctionNOTFOUND({
                            functionName: action.functionName
                        });
                }
            })
        )
    );

    /*spzApiLoadValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(runOnChangeFunction),
            withLatestFrom(this.store$),
            switchMap(([_action, state]) => {
                return spzApiLoadValues(state, this.apiService);
            })
        )
    );*/

  constructor(
    private actions$: Actions,
    private autocompleteItemsService: AutocompleteApiService,
    private store$: Store<State>,
    private apiService: ApiService,
    private http: HttpClient
  ) {}
}
