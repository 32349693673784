import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-saved-offer-dialog',
  templateUrl: './saved-offer-dialog.component.html',
  styleUrls: ['./saved-offer-dialog.component.scss']
})
export class SavedOfferDialogComponent implements OnInit {
  loading: boolean = true;
  text: string = 'Hľadám ponuku v systéme...';
  error: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SavedOfferDialogComponent>
  ) {

  }

  ngOnInit() {
  }

  errorFromServer() {
    this.loading = false;
    this.error = true;
  }

  close() {
    this.dialogRef.close();
  }

}
