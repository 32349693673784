import { createSelector } from '@ngrx/store';

import { AutocompleteItemsEnum } from '../../models/autocomplete-items.enum';
import { City } from '../../models/dto/city';
import { VehicleSalesName } from '../../models/dto/vehicles-sales-name';
import { State } from '../reducers';
import { AutocompleteItemsState } from '../reducers/autocomplete-items.reducer';
import { FormValuesState } from '../reducers/form-values.reducer';
import { selectFormValues } from './form-values.selectors';

export const selectAutocompleteItemsState = (state: State) =>
  state.autocompleteItems;

export const selectAutocompleteItems = (itemsCode: AutocompleteItemsEnum) =>
  createSelector(
    selectAutocompleteItemsState,
    (state: AutocompleteItemsState) => {
      const data = state[itemsCode];
      if (
        itemsCode === AutocompleteItemsEnum.VehicleBrand ||
        itemsCode === AutocompleteItemsEnum.VehicleSalesName
      ) {
        const brands = Object.keys(data).map((key) => data[key]);
        const firstItems = brands.filter(
          (item) => firstBrands.indexOf(item.title) !== -1
        );
        return [
          ...firstItems,
          ...brands
            .filter((item) => firstBrands.indexOf(item.title) === -1)
            .sort((obj1, obj2) => {
              return obj1.title
                .toUpperCase()
                .localeCompare(obj2.title.toUpperCase());
            }),
        ];
      } else {
        return Object.keys(data).map((key) => data[key]);
      }
    }
  );

export const selectVehiclesSalesModelItems = createSelector(
  selectAutocompleteItemsState,
  selectFormValues,
  (items: AutocompleteItemsState, formValues: FormValuesState) => {
    const selectedBrandId = formValues[AutocompleteItemsEnum.VehicleBrand];
    if (selectedBrandId) {
      const data = items[AutocompleteItemsEnum.VehicleSalesName];
      return Object.keys(data)
        .map((key) => data[key])
        .filter(
          (vehicleSalesname: VehicleSalesName) =>
            vehicleSalesname.brandId === selectedBrandId
        );
    } else {
      return [];
    }
  }
);

export const selectCitiesItems = (zipCodeFieldCode: string) =>
  createSelector(
    selectAutocompleteItemsState,
    selectFormValues,
    (state: AutocompleteItemsState, formValues: FormValuesState) => {
      const zipCode = formValues[zipCodeFieldCode];
      if (zipCode) {
        const allCities = state[AutocompleteItemsEnum.City];
        if (allCities) {
          return Object.keys(allCities)
            .map((key) => allCities[key])
            .filter((city: City) => city.zipCodes.indexOf(zipCode) > -1);
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
  );

const firstBrands: Array<string> = [
  'AUDI',
  'BMW',
  'CITROEN',
  'FIAT',
  'FORD',
  'HONDA',
  'HYUNDAI',
  'KIA',
  'MAZDA',
  'MERCEDES-BENZ',
  'NISSAN',
  'OPEL',
  'PEUGEOT',
  'RENAULT',
  'SEAT',
  'SUZUKI',
  'ŠKODA',
  'TOYOTA',
  'VOLKSWAGEN',
  'VOLVO',
];
