<app-loader *ngIf="loading" [text]="text"></app-loader>

<div *ngIf="!loading">
  <h3 *ngIf="!error">Ďakujeme. Ozveme sa čo najskôr.</h3>
  <h3 *ngIf="error">Mrzí nás to, ale nastala chyba a číslo k nám nebolo doručené.</h3>
  <mat-dialog-actions>
    <div class="text-center">
      <button (click)="close()" mat-raised-button class="mat-raised mat-primary mat-button mat-ink-ripple">Zavrieť</button>
    </div>
  </mat-dialog-actions>
</div>
