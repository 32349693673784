import { createSelector } from '@ngrx/store';
import { compareOffersForSortine } from 'app/functions/compare-offers-for-sortine';
import { PriceOffer } from 'app/models/price-offer';
import _ from 'underscore';

import { PriceOfferStatusEnum } from '../../models/price-offer-status.enum';
import { State } from '../reducers';
import { PriceOffersState } from '../reducers/price-offer.reducer';

export const selectPriceOffers = (state: State) => state.priceOffers;

export const selectPriceOffersAsOrderedArray = createSelector(
  selectPriceOffers,
  (priceOffers: PriceOffersState) =>
    _.values(priceOffers.entities).sort(compareOffersForSortine)
);

export const selectAXANotConfirmedOffers = createSelector(
  selectPriceOffersAsOrderedArray,
  (priceOffers: PriceOffer[]) =>
    priceOffers.filter(
      (offer: PriceOffer) =>
        offer.priceStatus === PriceOfferStatusEnum.AXA_CONFIRM_REQUIRED ||
        offer.priceStatus === PriceOfferStatusEnum.UNIQA_CONFIRM_REQUIRED
    )
);

export const selectDebugPriceOffers = createSelector(
  selectPriceOffers,
  (priceOffers: PriceOffersState) => priceOffers.debugPriceOffers
);

export const selectOffersLoadingCount = createSelector(
  selectPriceOffers,
  (priceOffers: PriceOffersState) => priceOffers.offersLoadingCount
);
