import {Moment} from 'moment';
import {onChangeFunctionNOTFOUND, setFormFieldValues} from '../../+state/actions/form-values.action';
import {State} from '../../+state/reducers';
import {selectFormValues} from '../../+state/selectors/form-values.selectors';

export function poznateSpzChange(state: State) {
const formValues = selectFormValues(state);
    const ecvKnow = formValues['ecv-know'];
    if (ecvKnow === 'Nie') {
        return setFormFieldValues({
            newFieldValues: [{
            fieldCode: 'spz-api-pomocne',
            value: 'NOSPZ'
        }]});
    } else {
        return setFormFieldValues({
            newFieldValues: [{
                fieldCode: 'spz-api-pomocne',
                value: ''
            }]});
    }
}
