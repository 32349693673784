import { Moment } from 'moment';
import * as moment from 'moment';

import {
  onChangeFunctionNOTFOUND,
  setFormFieldValues
} from '../../+state/actions/form-values.action';
import { State } from '../../+state/reducers';
import { selectFormValues } from '../../+state/selectors/form-values.selectors';
import { DateFieldValue } from '../../models/date-field-value';
import { NewFormFieldValue } from '../../models/new-form-field-value';

export function setAgeOfOwnerField(state: State) {
  const formValues = selectFormValues(state);
  const birthDate: Moment = formValues['owner-birthdate'];
  const startInsurance: Moment = formValues['start-of-insurence'];

  if (birthDate && startInsurance) {
    const newFormValues: NewFormFieldValue[] = [];
    const age = moment().diff(birthDate, 'years');

    newFormValues.push({ fieldCode: 'age-of-owner', value: age.toString() });

    // TODO:
    if (age < 18) {
      // nema 18 rokov
      //this.formGroup.get(this.field.code).setErrors({ birthNameNot18: true });
    } else {
      // ma 18 rokov
    }

    const startInsurenceAge = startInsurance.diff(birthDate, 'years');
    newFormValues.push({
      fieldCode: 'insur-age-start-insurance',
      value: startInsurenceAge.toString()
    });

    const ageGroupama = +startInsurance.format('YYYY') - birthDate.year();
    newFormValues.push({
      fieldCode: 'insur-age-start-insurance-grop',
      value: ageGroupama.toString()
    });

    // TODO: ??? WHY?
    /*let minYear = year + 17;
            let field = this.firstStepService.getFieldByCode('license-received-when');
            if (field) {
                field.minYear = minYear;
            }*/

    return setFormFieldValues({
      newFieldValues: newFormValues
    });
  } else {
    return onChangeFunctionNOTFOUND({
      functionName: 'setAgeOfOwnerField()',
      errorMessage: 'NOT ALL REQUIRED FIELDS ARE FILLED'
    });
  }
}
