import {
  onChangeFunctionNOTFOUND,
  setFormValue
} from '../../+state/actions/form-values.action';
import { State } from '../../+state/reducers';
import { City } from '../../models/dto/city';

export function fillSpzRegionField(state: State) {
  const city: City = state.formValues['vehicle-city'];
  const cityItem: any = Object.values(state.autocompleteItems['city']).filter(
    (c:any) => c.id === city
  );
  if (cityItem.length > 0) {
    return setFormValue({
      fieldCode: 'spz-region',
      value: cityItem[0].plateNumbers
    });
  }
  return onChangeFunctionNOTFOUND({
    functionName: 'fillSpzRegionField()',
    errorMessage: 'CITY NOT FOUND'
  });
}
