import { Injectable } from '@angular/core';
import { Validators } from "@angular/forms";

@Injectable()
export class FieldsConditionsService {
  conditionsTree:any = {};

  constructor(    
  ) { 

  }

  clearConditionsTreet() {
      this.conditionsTree = {};
  }

  addConditions(conditionsTree) {
      for (var property in conditionsTree) {
            if (conditionsTree.hasOwnProperty(property)) {
                if (this.conditionsTree.hasOwnProperty(property)) {                    
                    conditionsTree[property].forEach(condition => {
                        this.conditionsTree[property].push(condition);    
                    });
                } else {
                    this.conditionsTree[property] = conditionsTree[property];
                }
            }
        }
      //this.conditionsTree = Object.assign(this.conditionsTree, conditionsTree);
  }

  updateFormsByField(field, forms, formGroup) {
      if (this.conditionsTree.hasOwnProperty(field.id)) {
          //console.log('have conditions!');
          //console.log(this.conditionsTree[field.id]);          
          return this.updateChildrenInForms(field, forms, formGroup);
      } else {
          return forms;
      }
  }

  updateChildrenInForms(field, forms, formGroup) {   
              
      //console.log('updateChildrenInForms for field id '+field.id);      
      let conditions = this.conditionsTree[field.id];            
      //console.log(conditions);  
      ////console.log(forms);    
      let updateChildrenInFormsForFields = [];

      for (let form of forms) {
        let showForm = false;
        for (let f of form.fields) {            
            let showField; // = true;
            let isRequired = false;

            //console.log('UPDATE FIELD CONDITIONS FOR FIELD ID='+f.id, f);
            
            if (!f.disabled) {
                if (conditions) {
                    for (let condition of conditions) {
                        if (condition.targetId==f.id) {   
                            //console.log('suttisfied condition:');                                              
                            //console.log(condition);
                            //console.log(field.value);
                            if (field.show===false) {
                                showField = false;
                            } else {
                                if (condition.sourceValue=="-any-" && typeof field.value!=='undefined' && field.value!=='') {                        
                                    showField = true;
                                } else {
                                    let splitted = condition.sourceValue.split(';'); 
                                    if (splitted.indexOf(field.value)>-1) {                                                    
                                        showField = true;
                                    } else {                                                    
                                        showField = false;
                                    }
                                }
                            }
                            if (showField && condition.isRequired) {
                                isRequired = true;
                            }                        
                        }
                    }
                }

                if (typeof showField !== 'undefined') {
                    //if (f.show===false && showField===true) {
                    if (showField===true) {

                        //console.log('SHOW FIELD: ', f);

                        // ADD REQUIRED VALIDATOR
                        f.formControl.forEach((fc)=>{
                            fc.enable();    

                            let existingValidators = f.validators;

                            if (isRequired) {
                                f.isRequired = true;
                                
                                if (fc.customValidators) {
                                    existingValidators = [...fc.customValidators, Validators.required];
                                } else {
                                    if (existingValidators.length>0) {                                        
                                        existingValidators = [...existingValidators, Validators.required];
                                    } else {
                                        existingValidators = [Validators.required];
                                    }                                    
                                }
                            }

                            f.validators = existingValidators;

                            //console.log(fc);
                            //console.log('set validators: ', existingValidators);

                            fc.setValidators(existingValidators);
                            fc.updateValueAndValidity();                                
                        });
                    }             
                    
                    if (f.show===true && showField===false) {
                        // REMOVE REQUIRED VALIDATOR
                        f.formControl.forEach((fc)=> {
                            fc.disable();    
                            fc.clearValidators();
                            fc.updateValueAndValidity();
                            fc.setValue('');                                                                            
                        });
                        f.clearValue();
                        updateChildrenInFormsForFields.push(f);
                        f.isRequired = false;
                    }
                    
                    f.show = showField;
                }
            }

            if (f.show && !f.hiddenByRule) {
                showForm = true;
            }
        }
        form.empty = !showForm;
      }
    
      for (let f of updateChildrenInFormsForFields) {  
        forms = this.updateChildrenInForms(f, forms, formGroup);
      }

      return forms;
                        
  }
}