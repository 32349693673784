import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ChooseProductModalComponent} from '../../components/choose-product-modal/choose-product-modal.component';
import {CategoriesService} from '../../services';
import { ApiService } from '../../services/api.service';
import { LayoutService } from '../../services/layout.service';
import {map, take} from 'rxjs/operators';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-document-page',
  templateUrl: './document-page.component.html',
  styleUrls: ['./document-page.component.scss']
})
export class DocumentPageComponent implements OnInit, OnDestroy {
  document;
  sub: Subscription;
  sub2: Subscription;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private layoutService: LayoutService,
    private sanitizer: DomSanitizer,
    private categoriesService: CategoriesService,
    private dialog: MatDialog
  ) {
    layoutService.setBodyClasses(['static-page']);
  }

  ngOnInit() {
    this.sub = this.route.data
      .subscribe((data: any) => {
        if (data.documentId !== 0) {
          this.apiService.get('/document-template/' + data.documentId)
            .pipe(take(1))
            .subscribe(document => {
              document.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(document.content);
              this.document = document;
                setTimeout(() => {
              this.prepareEvents();
                }, 2000);
            });
        } else {
          this.route.paramMap.pipe(take(1)).subscribe((params: ParamMap) => {
            const documentId = params.get('documentId');
            if (documentId) {
              this.apiService.get('/document-template/' + documentId)
                .pipe(take(1))
                .subscribe(document => {
                    document.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(document.content);
                  this.document = document;
                    setTimeout(() => {
                        this.prepareEvents();
                    }, 2000);
                });
            }
          })
        }

        if (data.class) {
          this.layoutService.setBodyClasses(['product']);
        }
      });
  }

      ngOnDestroy(): void {
        this.sub.unsubscribe();
      }

    prepareEvents() {
        Array.from(document.getElementsByClassName('click-button')).forEach(
            (element, index, array) => {
                const categorySlug = element.getAttribute('kategoria')
                element.addEventListener('click', () => {
                    const category = this.categoriesService.getCategoryBySlug(categorySlug);
                    if (category) {
                        this.showProductsModal(category.title, category.products);
                    }
                });
            }
        );

        /*[...document.getElementsByClassName('click-button')].forEach(()=>{
          alert('click');
        });*/

        /*.forEach(element => {
            element.addEventListener('click', ()=>{
              alert('click');
            });
        });*/
    }

    showProductsModal(title: string, products) {
        const dialogRef = this.dialog.open(ChooseProductModalComponent, {
            panelClass: 'grey-modal'
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.products = products;

    }

}
