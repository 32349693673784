import {Condition} from './condition';
import {FieldRuleDto} from './field-rule-dto';
import {RuleDto} from './rule-dto';

export enum ValueTypeEnum {
    text = 'text',
    choice = 'choice',
    radio = 'radio',
    number = 'number',
    date = 'date',
    vehicleBrand = 'vehicle-brand',
    vehicleModel = 'vehicle-model',
    hidden = 'hidden',
    year = 'year',
    autocomplete = 'autocomplete',
    calendar = 'calendar',
    spz = 'spz',
    spzApi = 'spz-api',
    textWithExtraOption = 'text-with-extra-option',
    city = 'city'
}

export type FieldDto = {
    id: number,
    valueType: ValueTypeEnum,
    fieldLabel: string,
    description?: string
    options?: string,
    code: string
    isRequired: boolean,
    rules: RuleDto[],
    fieldRules: FieldRuleDto[],
    conditions: Condition[],
    fieldOrder: number,
    advancedOptions?: any[],
    defaultValue?: any,
};

