import {createSelector} from '@ngrx/store';

import {State} from '../reducers';
import {FirstStepFieldsState} from '../reducers/first-step-fields.reducer';

export const selectFirstStepFieldsState = (state: State) => state.firstStepFields;

export const selectFirstStepFields = createSelector(
    selectFirstStepFieldsState,
    (state: FirstStepFieldsState) => state.fields
);
