import {createSelector} from '@ngrx/store';

import {State} from '../reducers';
import {FirstStepFieldsState} from '../reducers/first-step-fields.reducer';

export const selectThirdStepFieldsState = (state: State) => state.thirdStepFields;

export const selectThirdStepFields = createSelector(
    selectThirdStepFieldsState,
    (state: FirstStepFieldsState) => state.fields
);
