export const mockFOThirdStep = [
  { code: 'title-before-name', value: 'Mgr.' },
  { code: 'name', value: 'Jakub' },
  { code: 'surname', value: 'Test' },
  { code: 'street-residence', value: 'Bottova' },
  { code: 'house-number', value: '88825' },
  { code: 'zip-code-residence', value: null },
  { code: 'city-residence', value: null },
  { code: 'vehicle-insur-id-number-po', value: null },
  { code: 'vehicle-insur-id-number-fop', value: null },
  { code: 'identification-number', value: '995309/6021' },
  { code: 'evidence-number', value: 'SP321654' },
  { code: 'executive-decision', value: null },
  { code: 'type-evidence-number', value: 'Občianský preukaz' },
  { code: 'insur-titl-before-name-executive', value: null },
  { code: 'person_nationality', value: null },
  { code: 'insur-name-executive', value: null },
  { code: 'insur-surname-executive', value: null },
  { code: 'insur-id-number-executive', value: null },
  { code: 'isur-street-executive', value: null },
  { code: 'insur-stret-numb-executive', value: null },
  { code: 'isur-zipcode-executive', value: null },
  { code: 'insur-city-executive', value: null },
  { code: 'insur-state-executive', value: null },
  { code: 'nationality-executive', value: null },
  { code: 'isur-evidence-number-executive', value: null },
  { code: 'executive-type-evidence-number', value: null },
  { code: 'vehicle-owner-kind', value: 'Právnická osoba' },
  { code: 'vehicle-owner-company-name-fop', value: null },
  { code: 'vehicle-owner-company-name-po', value: null },
  { code: 'vehicle-owner-id-number-fop', value: null },
  { code: 'vehicle-owner-id-number-po', value: null },
  { code: 'vehicle-owner-title-before-personal-name', value: null },
  { code: 'vehicle-owner-personal-name', value: null },
  { code: 'vehicle-owner-personal-surname', value: null },
  { code: 'vehicle-owner-id-number-personal', value: null },
  { code: 'vehicle-owner-street', value: null },
  { code: 'vehicle-owner-stret-number', value: null },
  { code: 'owner-zip-code-residence', value: null },
  { code: 'owner-city-residence', value: null },
  { code: 'street-corres', value: null },
  { code: 'house-number-corres', value: null },
  { code: 'zip-code-corres', value: null },
  { code: 'city-corres', value: null },
  { code: 'country-corres', value: 'SK' },
  { code: 'ecv-number', value: 'Vozidlo pred registráciou' },
  { code: 'spz-car', value: null },
  { code: 'tp-number', value: null },
  { code: 'car-first-evidence', value: '2020-02-29T23:00:00.000Z' },
  { code: 'car-color', value: 'Biela' },
  { code: 'car-seats-count', value: '5' },
  { code: 'transmision', value: 'Manuála 4R' },
  { code: 'responsibility-insurer', value: 'v žiadnej poisťovni' },
  { code: 'payment-method', value: 'poštovou poukážkou' },
  { code: 'wus-pz-1', value: null },
  { code: 'unipo-pz-1', value: null },
  { code: 'wus-pz-2', value: null },
  { code: 'wus-pz-3', value: null },
  { code: 'wus-pz-4', value: null },
  { code: 'child-name-surname', value: null },
  { code: 'note-to-contract', value: null }
];
