
  <h2 mat-dialog-title>Pripojte sa na internet</h2>
  <mat-dialog-content>Prosím skontrolujte Vaše pripojenie na internet a potom skúste pokračovať v práci.</mat-dialog-content> 

  <mat-dialog-actions>
    <div class="text-center">
      <button mat-raised-button (click)="restart()" class="mat-raised mat-primary mat-button mat-ink-ripple">Pokračovať</button>
    </div>
  </mat-dialog-actions>


