import { createAction, props } from '@ngrx/store';

import {NewFormFieldValue} from '../../models/new-form-field-value';

export const setFormFieldValues = createAction(
    '[FormValues] Set Form Field Values',
    props<{ newFieldValues: NewFormFieldValue[] }>()
);

export const setFormValue = createAction(
  '[FormValues] Set Form Value',
  props<{ fieldCode: string; value: string }>()
);

export const clearAllFormValues = createAction(
  '[FormValues] Clear all form values'
);

export const setFormValues = createAction(
  '[FormValues] Set Form Values',
  props<{ formValues: any }>()
);

export const runOnChangeFunction = createAction(
  '[FormValue] Run OnChange function',
  props<{
    functionName: string;
  }>()
);

export const onChangeFunctionNOTFOUND = createAction(
  '[FormValue] OnChange function NOT FOUND',
  props<{ functionName: string, errorMessage?: string }>()
);
