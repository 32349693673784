import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { CategoriesService } from '../../services/categories.service';
import { SavedOfferDialogComponent } from '../../components/saved-offer-dialog/saved-offer-dialog.component';
import { SavedOfferService } from '../../services/saved-offer.service';
import { InsurenceService } from '../../services/insurence.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CallMeComponent } from '../../components/call-me/call-me.component';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  callMe:any = {
    phone: '',
    when: 'dnes'
  };

  loading: boolean = false;
  error: boolean = false;
  success: boolean = false;

  constructor(    
    private dialog: MatDialog,    
    private api: ApiService,
    public dialogRef: MatDialogRef<ContactComponent>
  ) {

  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  callMeSubmitted() {
    this.loading = true;
    this.api.post('/call-me', {
      phone: '+421 '+this.callMe.phone//,
      //when: this.callMe.when
    })
      .subscribe(result=>{
       // console.log('call-me-offer result: ', result);    
        this.callMe = {
          phone: '',
          when: 'dnes'
        };
        this.loading = false;    
        this.success = true;
        /*let dialogRef = this.dialog.open(ModalInfoComponent, {
            data: {
              message: 'Ďakujeme, ozveme sa.'
            }
        });  */
      });
  }

  finished() {
    this.loading = false;
  }

  errorFromServer() {
    this.loading = false;
    this.error = true;
  }

}

/*import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { CategoriesService } from '../../services/categories.service';
import { LoadingOffersComponent } from '../../components/saved-offer-dialog/saved-offer-dialog.component';
import { SavedOfferService } from '../../services/saved-offer.service';
import { InsurenceService } from '../../services/insurence.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CallMeComponent } from '../../components/call-me/call-me.component';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  phone: string;

  constructor(
    private layoutService: LayoutService,
    private apiService: ApiService,
    private dialog: MatDialog
  ) { 
    layoutService.setBodyClasses(['contact']);    
  }

  ngOnInit() {
  }

  send() {
    if (this.phone) {
      let dialogRef = this.dialog.open(CallMeComponent, {
          data: {}
      });  
      this.apiService.post('/call-me', {phone: this.phone}).subscribe(data=>{
        this.phone = '';  
        dialogRef.componentInstance.finished();
      }, error => {
        dialogRef.componentInstance.errorFromServer();
      });
      
    }
  }

  onSubmit() {
    this.send();
  }

}*/
