import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choose-product-modal',
  templateUrl: './choose-product-modal.component.html',
  styleUrls: ['./choose-product-modal.component.scss']
})
export class ChooseProductModalComponent {
  title: string;
  products: any[] = [];

  constructor(public dialogRef: MatDialogRef<ChooseProductModalComponent>, private router: Router) { }

  close() {
    this.dialogRef.close();
  }

  navigate(product:any) {        
    this.router.navigate(['/p', product.slug, product.id]);    
    this.close();
  }

}
