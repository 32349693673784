export const mockFOFirstStep = [
  { code: 'start-of-insurence', value: '2020-04-29T22:00:00.000Z' },
  { code: 'koop-car', value: 'osobne auto' },
  { code: 'kat_MV', value: 'M1' },
  { code: 'vehicle-production-year', value: 2014 },
  { code: 'vehicle-brand', value: 109 },
  { code: 'vehicle-sales-name', value: 1194 },
  { code: 'fuel-type', value: 'Benzín' },
  { code: 'engine-power', value: '150' },
  { code: 'engine-capacity', value: '150' },
  { code: 'vehicle-weight', value: '1500' },
  { code: 'vin', value: 'C98203' },
  { code: 'vehicle-holder-kind', value: 'Fyzická osoba' },
  { code: 'owner-birthdate', value: '1991-12-31T23:00:00.000Z' },
  { code: 'zip-code', value: '01332' },
  { code: 'vehicle-city', value: 564 },
  { code: 'is-corres-address-different', value: 'Áno' },
  { code: 'vehicle-owner', value: 'Je totožný s držiteľom a poistníkom' },
  { code: 'spz-region', value: 'ZA,ZI' },
  { code: 'pzp_grop_kod_psc', value: '88' },
  { code: 'age-of-owner', value: '28' },
  { code: 'insur-age-start-insurance-grop', value: '28' },
  { code: 'insur-age-start-insurance', value: '28' },
  { code: 'Credit_History', value: 'Nie' },
  { code: 'did-you-caused-pzp', value: 'Nie' },
  { code: 'accident_confirmation_pzp', value: 'Do 12 mesiacov' },
  { code: 'claim-last-1-12-months', value: '0' },
  { code: 'claim-last-13-24-months', value: '0' },
  { code: 'claim-last-25-36-months', value: '0' },
  { code: 'claim-last-37-72-months', value: '0' },
  { code: 'claim-last-48-96-months', value: '0' },
  { code: 'claim-last-3-years', value: '0' },
  { code: 'claim-last-2-years', value: '0' },
  { code: 'claim-last-8-years', value: '0' },
  { code: 'ste-rodicom-dietata-vo-veku-do-15-rokov', value: 'Nie' },
  { code: 'child-born-date', value: 'null' },
  { code: 'insur-and-sale', value: 'Nie' },
  { code: 'has-axa-insurence', value: 'null' },
  { code: 'axa-pz-1', value: 'null' },
  { code: 'has-grop-insurence', value: 'null' },
  { code: 'grop-pz', value: 'null' },
  { code: 'has-union-insurence', value: 'null' },
  { code: 'has-wustenrot-insurence', value: 'null' },
  { code: 'wustenrot-contracts-count', value: 'null' },
  { code: 'phone', value: '0905432100' },
  { code: 'email', value: 'test@ideya.sk' }
];
