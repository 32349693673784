import {createSelector} from '@ngrx/store';

import {State} from '../reducers';
import {FirstStepFieldsState} from '../reducers/first-step-fields.reducer';

export const selectExtraFieldsState = (state: State) => state.extraFields;

export const selectExtraFields = createSelector(
    selectExtraFieldsState,
    (state: FirstStepFieldsState) => state.fields
);
