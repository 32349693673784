
  <app-loader class="product-page-loader" *ngIf="loading" [text]="'Pripravujem formulár...'"></app-loader>

  <div *ngIf="!loading">

    <h1 [innerHTML]="product?.titleForm" id="product-form-title"></h1>

    <div class="zero">

      <div class="stepsContainer">

        <!-- STEP 1 -->
        <div id="stepHash1" class="step" [ngClass]="{active: currentStep==1, collapsed: currentStep!=1, done: currentStep>1}">
          <app-step1-fields (goToThisStep)="goToStep(1)" [currentStep]="currentStep" [product]="product" *ngIf="product" (validSubmitted)="firstStepSubmitted()"></app-step1-fields>		
        </div>

        <!-- STEP 2 -->
        <div id="stepHash2" class="step" [ngClass]="{active: currentStep==2, collapsed: currentStep!=2, done: currentStep>2, waiting: currentStep<2}">
          <app-step2-offers (goToThisStep)="goToStep(2)" [currentStep]="currentStep" [product]="product" (offerChosen)="offerChosen($event)"></app-step2-offers>
        </div>

        <!--- STEP 3 -->
        <div id="stepHash3" class="step" [ngClass]="{active: currentStep==3, collapsed: currentStep!=3, done: currentStep>3, waiting: currentStep<3}">						
          <app-step3-fields (goToThisStep)="goToStep(3)" [currentStep]="currentStep" [product]="product" (validSubmitted)="thirdStepSubmitted()"></app-step3-fields>
        </div>

        <!--- STEP 4 -->
        <div id="stepHash4" class="step" [ngClass]="{active: currentStep==4, collapsed: currentStep!=4, done: currentStep>4, waiting: currentStep<4}">
          <app-step4-preview (goToThisStep)="goToStep(4)" [currentStep]="currentStep" [product]="product" (validSubmitted)="fourthStepSubmitted()"></app-step4-preview>
        </div>

        <!--- STEP 5 -->
        <div id="stepHash5" class="step" [ngClass]="{active: currentStep==5, collapsed: currentStep!=5, done: isStepDone(5), waiting: currentStep<5}">
          <app-step5-final [currentStep]="currentStep"></app-step5-final>
        </div>		

    </div>

  </div>
</div>
