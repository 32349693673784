import { Injectable } from '@angular/core';
import { FieldsConditionsService } from './fields-conditions.service';
import { FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AutocompleteItemsService } from './autocomplete-items.service';

@Injectable()
export class FieldsProcessorService {

  constructor(
	private fieldConditionsService: FieldsConditionsService,
	private autocompleteItemsService: AutocompleteItemsService
  ) {

  }

  processForms(forms, formObject, savedOfferFormData, chosenOffer) {

    forms = forms.sort((f1,f2)=>f1.formOrder-f2.formOrder);

	let conditionsTree = {};
	let insConditionsTree = {};

    for (let form of forms) {
	  form.empty = false;
      form.fields = form.fields.sort((f1,f2)=>f1.fieldOrder-f2.fieldOrder);
      for (let field of form.fields) {

		//field.value = undefined;
		field.show = true;	
		field.disabled = false;	
		if (field.defaultValue) {
			field.value = field.defaultValue;
		}
		let validators = [];
		let validatorsWR = []; // without Required

		let createFormControl = true;
		field.clearValue = function() {
			this.value = undefined;
			if (field.defaultValue) {
				field.value = field.defaultValue;
			}
		}

		/** SET INITIAL DATA */
		if (savedOfferFormData && savedOfferFormData.hasOwnProperty(field.id)) {
			field.value = savedOfferFormData[field.id];
		}
		
		/*const firstField = this.firstStepService.getFieldByCode(field.code);
        if (firstField) {
          field.value = firstField.value;
        }*/

		/** REQUIRED FIELDS */
		if (field.isRequired) { // || field.conditions.length>0) {
			//validators.push(this.requiredFieldValidator);
			validators.push(Validators.required);			
		}

		field.rules.forEach(rule => {
			if (rule.ruleType=='disabled') {
				field.disabled = true;
			}
			if (rule.ruleType=='hidden') {
				field.hiddenByRule = true;
			}
			if (rule.ruleType=='pattern-regexr') {
				validators.push(Validators.pattern(rule.ruleValue));
				validatorsWR.push(Validators.pattern(rule.ruleValue));
			}
			if (rule.ruleType=='birth-number') {
				//validators.push(Validators.pattern('\\b\\d{6}[\\/]*\\d{3,4}\\b'));
				//validators.push(birthNumberValidator());
				//validatorsWR.push(birthNumberValidator());
				field.isBirthNumber = true;
				/*$scope.field.pattern = new RegExp(rule.ruleValue);//"\b\d{6}[\/]*\d{3,4}\b"
				$scope.isBirthNumber = true;*/
			  }
		});

		//validators.push(Validators.pattern('/^[a-zA-Z0-9]{5}'));

		if (field.code == 'email') {
			//console.log('EMAIL!');
			//console.log(field);
			field.rules.forEach(rule => {
				if (rule.ruleType=='pattern-regexr') {
					validators.push(Validators.pattern(rule.ruleValue));
					validatorsWR.push(Validators.pattern(rule.ruleValue));
				}
				/*if (rule.ruleType=='onchange') {
					field.onChange = rule.ruleValue;
				}*/
				/*if (rule.ruleType=='hidden') {
					field.hideAlways = true;
				}*/
				/*if (rule.ruleType=='max') {
					validators.push(Validators.pattern(rule.ruleValue));
					//field.max = rule.ruleValue;
				}
				if (rule.ruleType=='min') {
					field.min = rule.ruleValue;
				}*/
			});
		}

        /** FIELD OPTIONS **/
        if (field.options && typeof(field.options)==='string' && field.valueType!='text-with-extra-option') {
          field.options = field.options.split(';');
		}
		
		/** CALENDAR **/
		// validation for MIN is only set on calendar
		

		/** YEAR **/
		if (field.valueType=='year') {			
			if (field.options=='past') {
				var date = new Date();
				field.minYear = date.getFullYear()-100;
				field.maxYear = date.getFullYear();
			}				
		}

		/** NUMBER **/
		if (field.valueType=='number') {	
			field.rules.forEach(rule=>{
				if (rule.ruleType=='min') {									
					validators.push(Validators.min(rule.ruleValue));
					field.min = rule.ruleValue;
				}
				if (rule.ruleType=='max') {					
					validators.push(Validators.max(rule.ruleValue));
					field.max = rule.ruleValue;
				}
			});								
		}

		/** AUTOCOMPLETE ITEMS */
		if (field.valueType == 'autocomplete' || field.valueType == 'city' || field.valueType=='vehicle-brand' || field.valueType=='vehicle-model' || (field.valueType=='text' && field.code.indexOf('zip-code')>-1)) {
			// TODO: AUTOCOMPLETE ITEMS :)
			field.items = [];
			field.allItems = [];
			let recordType = this.autocompleteItemsService.loadItemsOfType(field);
			field.recordType = recordType;
			this.autocompleteItemsService.itemsChange.subscribe(items=>{
				if (items.hasOwnProperty(recordType)) {					
					field.allItems = items[recordType];
					/*let value = field.value;
					field.value = null;
					field.value = value;*/
					//field.value = field.value;		

				}
			});
		}

		if (field.valueType=='city') {
			switch(field.code) {
				case 'vehicle-city':
					field.zipCodeFieldCode = 'zip-code';
					break;
				case 'city-residence':
					field.zipCodeFieldCode = 'zip-code-residence';
					break;
				case 'insur-city-executive':
					field.zipCodeFieldCode = 'isur-zipcode-executive';
					break;
				case 'owner-city-residence':
					field.zipCodeFieldCode = 'owner-zip-code-residence';
					break;
				case 'city-corres':
					field.zipCodeFieldCode = 'zip-code-corres';
					break;
			}
		}

		if (field.conditions.filter(condition=>{
			if (condition.sourceType=='offerMotherInsId' && chosenOffer && condition.sourceId==chosenOffer.motherInsCompanyId) {
				return true;
			} else {
				return false;
			}
		}).length>0) {
			validators.push(Validators.required);
		}

		/** DATE */
		if (field.valueType == 'date') {
			var date = new Date();
			if (field.options=='future') {
				field.minYear = date.getFullYear();
				field.maxYear = field.minYear+30;
			}

			if (field.options=='past') {
				field.minYear = date.getFullYear();
				field.maxYear = date.getFullYear()-50;
			}

			if (field.options=='birthdate') {
				field.maxYear = date.getFullYear()-90;
				field.minYear = date.getFullYear()-17;
				/*controller.changeFunctions.push({
					id: field.id,
					type: 'birthdate',
					targetFieldId: 25
				});*/
			}

			if (field.options=='child-birthdate') {
				// v tomto roku maximalne 16 rokov
				field.maxYear = date.getFullYear()-15;
				field.minYear = date.getFullYear();
				/*controller.changeFunctions.push({
					id: field.id,
					type: 'birthdate',
					targetFieldId: 25
				});*/
			}
			if (field.value==undefined) {
				field.value = {
					day: undefined,
					month: undefined,
					year: undefined
				};
			}
			
			formObject[field.code+'-day'] = new FormControl(null, validators);
			formObject[field.code+'-month'] = new FormControl(null, validators);
			formObject[field.code+'-year'] = new FormControl(null, validators);
			field.formControl = [
				formObject[field.code+'-day'],
				formObject[field.code+'-month'],
				formObject[field.code+'-year']
			];
			createFormControl = false;
			field.clearValue = function() {
				if (this.value) {
					this.value.day = undefined;
					this.value.month = undefined;
					this.value.year = undefined;
				}
			}
		}

		/** SPZ */
		if (field.valueType == 'spz') {
			console.log('REGION VALIDATORS:', validators);
			formObject[field.code+'-region'] = new FormControl(null, validators);
			//validators.push(Validators.pattern('/^[a-zA-Z0-9]{5}'));			
			validators.push(Validators.pattern('[a-zA-Z0-9]{5}'));
			//validatorsWR.push(Validators.pattern('[a-zA-Z0-9]{5}'));
			//validators.push(Validators.maxLength(5));	
			//validatorsWR.push(Validators.maxLength(5));	
			formObject[field.code+'-number'] = new FormControl(null, validators);
			formObject[field.code+'-number'].customValidators = validators;
			field.formControl = [
				formObject[field.code+'-region'],
				formObject[field.code+'-number']			
			];
			if (field.value==undefined) {
				field.value = {
					region: undefined,
					number: undefined
				};
			}
			createFormControl = false;
			switch(field.code) {
				case 'spz-car':
					field.zipCodeFieldCode = 'zip-code';
					field.zipCodeFieldCodeAlternative = 'zip-code-residence';
					break;					
			}
			field.clearValue = function() {
				if (this.value) {
					this.value.region = undefined;
					this.value.number = undefined;
				}
			}
		}

        /** CREATE CONDITIONS TREE **/
        if (field.conditions && field.conditions.length>0) {
          for (let condition of field.conditions) {

			/*condition.sourceType=='offerMotherInsId' && condition.sourceId!=controller.chosenOffer.motherInsCompanyId*/

			if (condition.sourceType=='field') {
				if (!conditionsTree.hasOwnProperty(condition.sourceId)) {
				conditionsTree[condition.sourceId] = [];
				}
				conditionsTree[condition.sourceId].push(condition);
			}

			if (condition.sourceType=='offerMotherInsId' && chosenOffer) {
				if (condition.sourceId!=chosenOffer.motherInsCompanyId) {
					field.isRequired = false;
					field.show = false;
					field.disabled = true;
				} else {					
					field.isRequired = true;
					field.disabled = false;
					field.show = true;
					//validators.push(Validators.required);
				}
			}

          }
		}
		
		if (createFormControl) {
			// adds form control for this field to form object	
			formObject[field.code] = new FormControl(null, validators);
			field.formControl = [formObject[field.code]];
			//formObject[field.code]['field'] = field;
		}

		field.validators = validatorsWR;
      }
    }

    //console.log('conditions:')
    //console.log(conditionsTree);

    this.fieldConditionsService.addConditions(conditionsTree);

    return forms;	
  }

  /*requiredFieldValidator(control: FormControl): {[s: string]: boolean} {
	  //console.log('VALIDATOR VALUE:');	
	  //console.log(control);

	  if (control['field']) {
		if (control['field'].show && (typeof control.value==='undefined' || control.value=='' || control.value===null)) {		  
			return {'required': true}
		}
	  	return null;
	  } else {
		return null;
	  }
  }*/

}
