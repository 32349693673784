import { setFormFieldValues } from '../../+state/actions/form-values.action';
import { State } from '../../+state/reducers';
import { NewFormFieldValue } from '../../models/new-form-field-value';

export function setClaimFields(state: State) {
  const newFormValues: NewFormFieldValue[] = [];
  const didYouClaimPzpField = state.formValues['did-you-caused-pzp'];

  const claim1To12Id = 'claim-last-1-12-months';
  const claim13to24Id = 'claim-last-13-24-months';
  const claim25to36Id = 'claim-last-25-36-months';
  const claim37to72Id = 'claim-last-37-72-months';
  const claim48to96Id = 'claim-last-48-96-months';

  const claim2yearsId = 'claim-last-2-years';
  const claim3yearsId = 'claim-last-3-years';
  const claim5yearsId = 'claim-last-5-years';
  const claim8yearsId = 'claim-last-8-years';

  if (
    didYouClaimPzpField === 'Nie' ||
    didYouClaimPzpField === 'Toto je moje prvé PZP'
  ) {
    newFormValues.push({ fieldCode: claim1To12Id, value: '0' });
    newFormValues.push({ fieldCode: claim13to24Id, value: '0' });
    newFormValues.push({ fieldCode: claim25to36Id, value: '0' });
    newFormValues.push({ fieldCode: claim37to72Id, value: '0' });
    newFormValues.push({ fieldCode: claim2yearsId, value: '0' });
    newFormValues.push({ fieldCode: claim3yearsId, value: '0' });
    newFormValues.push({ fieldCode: claim5yearsId, value: '0' });
    newFormValues.push({ fieldCode: claim8yearsId, value: '0' });
  } else {
    const claim1to12 = +state.formValues[claim1To12Id];
    const claim13to24 = +state.formValues[claim13to24Id];
    const claim25to36 = +state.formValues[claim25to36Id];
    const claim37to72 = +state.formValues[claim37to72Id];
    const claim48to96 = +state.formValues[claim48to96Id];

    if (
      !isNaN(claim1to12) &&
      !isNaN(claim13to24) &&
      !isNaN(claim25to36) &&
      !isNaN(claim37to72)
    ) {
      newFormValues.push({
        fieldCode: claim2yearsId,
        value: (claim1to12 + claim13to24).toString()
      });
      newFormValues.push({
        fieldCode: claim3yearsId,
        value: (claim1to12 + claim13to24 + claim25to36).toString()
      });
      newFormValues.push({
        fieldCode: claim5yearsId,
        value: (
          claim1to12 +
          claim13to24 +
          claim25to36 +
          claim37to72 +
          claim48to96
        ).toString()
      });
      newFormValues.push({
        fieldCode: claim8yearsId,
        value: (claim1to12 + claim13to24 + claim25to36 + claim37to72).toString()
      });
    } else {
      newFormValues.push({
        fieldCode: claim2yearsId,
        value: (claim1to12 + claim13to24).toString()
      });
      newFormValues.push({
        fieldCode: claim3yearsId,
        value: (claim1to12 + claim13to24 + claim25to36).toString()
      });
      newFormValues.push({
        fieldCode: claim5yearsId,
        value: (
          claim1to12 +
          claim13to24 +
          claim25to36 +
          claim37to72 +
          claim48to96
        ).toString()
      });
      newFormValues.push({
        fieldCode: claim8yearsId,
        value: (claim1to12 + claim13to24 + claim25to36 + claim37to72).toString()
      });
    }
  }

  return setFormFieldValues({
    newFieldValues: newFormValues
  });
}
