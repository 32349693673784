import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {DebugPriceOffer} from 'app/models/debug-price-offer';
import {PriceOffer} from 'app/models/price-offer';
import {Observable} from 'rxjs';

import {AutocompleteItemsEnum} from '../models/autocomplete-items.enum';
import {City} from '../models/dto/city';
import {FieldDto} from '../models/dto/field-dto';
import {VehicleSalesName} from '../models/dto/vehicles-sales-name';
import {AutocompleteItemsState} from './reducers/autocomplete-items.reducer';
import {selectAutocompleteItems, selectAutocompleteItemsState, selectCitiesItems, selectVehiclesSalesModelItems} from './selectors/app.selectors';
import {selectExtraFields} from './selectors/extra-fields.selectors';
import {selectFirstStepFields} from './selectors/first-step-fields.selectors';
import {selectFormFieldValue, selectFormValues} from './selectors/form-values.selectors';
import {selectDebugPriceOffers, selectOffersLoadingCount, selectPriceOffersAsOrderedArray, selectAXANotConfirmedOffers} from './selectors/price-offer.selectors';
import {selectThirdStepFields} from './selectors/third-step-fields.selectors';

@Injectable({
  providedIn: 'root'
})
export class StoreFacade {
  store$ = this.store;

  priceOffers$: Observable<PriceOffer[]> = this.store.select(
    selectPriceOffersAsOrderedArray
  );
  debugPriceOffers$: Observable<DebugPriceOffer[]> = this.store.select(
    selectDebugPriceOffers
  );
  selectVehiclesSalesModelItems$: Observable<VehicleSalesName[]> = this.store.select(
      selectVehiclesSalesModelItems
  );
  formValues$: Observable<any> = this.store.select(
      selectFormValues
  );
  firstFormFieldsAsArray$: Observable<FieldDto[]> = this.store.select(
      selectFirstStepFields
  );
  thirdStepfields$: Observable<FieldDto[]> = this.store.select(
      selectThirdStepFields
  );
  extrafields$: Observable<FieldDto[]> = this.store.select(
      selectExtraFields
  );
  allAutocompleteItems$: Observable<AutocompleteItemsState> = this.store.select(
      selectAutocompleteItemsState
  );
  offersLoadingCount$: Observable<number> = this.store.select(
      selectOffersLoadingCount
  );
  axaNotConfirmedOffers$: Observable<PriceOffer[]> = this.store$.select(
    selectAXANotConfirmedOffers
  );

  constructor(private store: Store<any>) {}

  selectCities$(zipCodeFieldCode: string): Observable<City[]> {
    return this.store.select(selectCitiesItems(zipCodeFieldCode));
  }

  autocompleteItems$(itemsCode: AutocompleteItemsEnum) {
    return this.store.select(selectAutocompleteItems(itemsCode));
  }

  fieldValue$(fieldId: string) {
    return this.store.select(selectFormFieldValue(fieldId));
  }

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
