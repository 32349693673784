import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { CategoriesService } from '../../services/categories.service';
import { SavedOfferDialogComponent } from '../../components/saved-offer-dialog/saved-offer-dialog.component';
import { SavedOfferService } from '../../services/saved-offer.service';
import { InsurenceService } from '../../services/insurence.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CallMeComponent } from '../../components/call-me/call-me.component';
import { ChooseCategoryModalComponent } from 'app/components/choose-category-modal/choose-category-modal.component';
import { DeviceService } from 'app/services';
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  FORMULARE_FE_URL = environment.FORMULARE_FE_URL;

  savedOfferNumber;
  insurenceCompanies: Array<any> = [];
  phone: string;

  constructor(
    private layoutService: LayoutService,
    private categoriesService: CategoriesService,
    private savedOfferService: SavedOfferService,
    private insurenceService: InsurenceService,
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private deviceService: DeviceService
  ) {
    layoutService.setBodyClasses(['homepage']);
    this.insurenceService.loadInsurenceCompanies().subscribe(companies=>{
      this.insurenceCompanies = companies.filter(comp=>comp.imageHq!='-' && comp.imageHq!=null);
    });
  }

  ngOnInit() {
  }

  callMe() {
    if (this.phone) {
      let dialogRef = this.dialog.open(CallMeComponent, {
          data: {}
      });
      this.apiService.post('/call-me', {phone: this.phone}).subscribe(data=>{
        this.phone = '';
        dialogRef.componentInstance.finished();
      }, error => {
        dialogRef.componentInstance.errorFromServer();
      });
    }
  }

  categoryClick(categoryId) {
    this.categoriesService.selectCategoryById(categoryId);
  }

  loadSavedOffer() {
    this.savedOfferService.loadSavedOffer(this.savedOfferNumber, false);
  }

  showCategoriesModal() {
    let options:any = {
      panelClass: 'grey-modal'
    }
    if (this.deviceService.isMobile()) {
      options.width = '100%';
      options.height = '100%';
    }
    let dialogRef = this.dialog.open(ChooseCategoryModalComponent, options);
  }

}
