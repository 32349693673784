<div mat-dialog-content>
    <div class="modal-info-content">
        <p>Našli sme údaje o vozidle:</p>
        <p>
            <ng-container *ngFor="let value of data.newFieldValues"><span *ngIf="value.name && value.value"><strong>{{ value.name }}:</strong> {{ value.valueHuman ? value.valueHuman : value.value }}<br/></span></ng-container>
        </p>
        <p style="font-size: 80%;">Zdroj údajov: overenie-vozidla.sk.</p>
    </div>
</div>
<div mat-dialog-actions class="actions">
  <button (click)="close()" mat-button class="mat-raised mat-primary mat-button mat-ink-ripple">Pokračovať</button>
</div>
