import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

declare var fbq: any;

@Injectable()
export class InsurenceService {
  private insurence: BehaviorSubject<any> = new BehaviorSubject(null);
  insurenceChange: Observable<any>;

  private insurenceErrors: BehaviorSubject<Array<any>> = new BehaviorSubject(
    []
  );
  insurenceErrorsChange: Observable<any>;

  constructor(private apiService: ApiService) {
    this.insurenceChange = this.insurence.asObservable();
    this.insurenceErrorsChange = this.insurenceErrors.asObservable();
  }

  clear() {
    this.insurence.next(null);
  }

  load(id) {
    return this.apiService.get('/insurence-company-content/' + id);
  }

  loadInsurenceCompanies() {
    return this.apiService.get('/insurence-companies');
  }

  cleanErrors() {
    this.insurenceErrors.next([]);
  }

  create(savedOfferId, contractForm, chosenOffer, contractPreview) {
    this.apiService
      .post('/insurence/create', {
        savedOfferId: savedOfferId,
        contractForm: contractForm,
        chosenOffer: chosenOffer,
        contractPreview: contractPreview
      })
      .pipe(take(1))
      .subscribe(
        data => {
          if (data.error && data.error == 1) {
            this.insurenceErrors.next(data.errors);
          }
          fbq('track', 'Purchase');
          this.insurence.next(data.insurence);
        },
        () => {
          this.insurenceErrors.next(['500']);
        }
      );
  }
}
