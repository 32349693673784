<div
  *ngIf="testEnvironment"
  style="background-color:red;width:100%;color:white;text-align:center;padding:7px 0;"
>
  Testovacie rozhranie
</div>

<div layout="column">
  <div class="header">
    <div class="container">
      <div class="row">
        <button class="header-contact" (click)="openContact()">
          Kontaktujte nás
        </button>

        <div class="top-help" hide show-gt-md>
          <div class="icon">
            <img src="assets/images/phone.svg" />
          </div>
          <div class="help-text">
            <p>0948 910 910</p>
            <p class="small">Po - Pi od 9:00 do 16:00</p>
          </div>
        </div>

        <a routerLink="/" class="logo-container">
          <img src="assets/images/logo@3x.png" />
        </a>
      </div>
    </div>
  </div>

  <div class="container content-container">
    <router-outlet></router-outlet>
  </div>

  <div class="home-ins-cont" *ngIf="isHome">
    <div class="home-ins container">
      <div class="ins-container">
        <h2 class="home-ins-title">Spolupracujeme s overenými partnermi</h2>
        <a
          class="home-ins-company"
          *ngFor="let insurenceCompany of insurenceCompanies"
          routerLink="poistovna/{{ insurenceCompany.id }}"
        >
          <img
            src="{{ insurenceCompany.imageHq.replace('http:', 'https:') }}"
          />
        </a>
      </div>
    </div>
  </div>

  <div class="footer-container">
    <div class="footer">
      <div class="container">
        <div class="footer-column">
          <a routerLink="/" class="footer-logo">
            <img src="assets/images/inverse-logo.svg" />
          </a>
        </div>
        <div class="footer-column">
          <h3>Online poistenie</h3>
          <a
            *ngFor="let category of categories"
            [routerLink]="['/kat', category.slug]"
          >
            {{ category.title }}
          </a>
        </div>
        <div class="footer-column">
          <h3>O nás</h3>
          <a routerLink="o-nas">Firma / Spoločnosť</a>
          <span (click)="openContact()">Kontakt</span>
          <a routerLink="faq">FAQ</a>
        </div>
        <div class="footer-column">
          <h3>Pre klienta</h3>
          <a routerLink="/obchodne-podmienky">Obchodné podmienky</a>
          <a routerLink="/bezpecnost">Bezpečnosť a dohľad</a>
          <a routerLink="/ochrana-osobnych-udajov">Ochrana osobných údajov</a>
          <a routerLink="/informacie-pre-klienta">Informácie pre klienta</a>
          <a routerLink="/cookies">Zásady používania cookies</a>
        </div>
      </div>
    </div>
    <div class="bottom-footer">
      <p class="copyright">
        © 2019 poistuj.sk | Všetky práva vyhradené<br />
        Na základe platnej licencie Národnej banky Slovenska. Číslo rozhodnutia
        ODT-2974-1/2012.<br />
        Licencia umožnuje sprostredkovať klientom finančné produkty a
        spolupracovať so všetkými poisťovňami na trhu.
      </p>
    </div>
  </div>
</div>
