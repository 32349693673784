import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { StoreFacade } from '../+state/store.facade';
import { SavedOfferDialogComponent } from '../components/saved-offer-dialog/saved-offer-dialog.component';
import { ValueTypeEnum } from '../models/dto/field-dto';
import { ApiService } from './api.service';

export const SAVED_OFFER_LOADING = 'SAVED_OFFER_LOADING';
export const SAVED_OFFER_LOADED = 'SAVED_OFFER_LOADED';
export const SAVED_OFFER_NOT_LOADING = 'SAVED_OFFER_NOT_LOADING';

@Injectable()
export class SavedOfferService {
  private state: BehaviorSubject<String> = new BehaviorSubject(
    SAVED_OFFER_LOADING
  );
  stateChange: Observable<String>;

  savedOfferNumber: number;
  savedOffer: any;
  firstFormData: any;
  dialogRef: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private storeFacade: StoreFacade
  ) {
    this.stateChange = this.state.asObservable();
  }

  clear() {
    this.firstFormData = undefined;
  }

  clearSavedOffer() {
    this.savedOffer = null;
  }

  setSavedOffer(savedOffer) {
    this.savedOffer = savedOffer;
  }

  getSavedOffer() {
    return this.savedOffer;
  }

  getSavedOfferNumber() {
    return this.savedOfferNumber;
  }

  noSavedOfferLoading() {
    this.state.next(SAVED_OFFER_NOT_LOADING);
  }

  sendByEmail(data) {
    return this.apiService.post('/sendsavedoffer', data);
  }

  loadSavedOffer(number, processData, loadAllData?) {
    this.state.next(SAVED_OFFER_LOADING);
    /*if (this.savedOfferNumber==number) {

        } else {*/
    this.savedOfferNumber = number;
    if (typeof this.dialogRef === 'undefined') {
      this.dialogRef = this.dialog.open(SavedOfferDialogComponent, {
        data: {
          savedOfferNumber: number,
        },
      });
      const sub = this.dialogRef.afterClosed().subscribe((result) => {
        this.dialogRef = undefined;
        sub.unsubscribe();
      });
    }
    this.apiService
      .get(
        loadAllData
          ? '/getsavedoffer/' + number + '/s65d4'
          : '/getsavedoffer/' + number
      )
      .subscribe(
        (data) => {
          //  console.log('res:', data);
          this.processSavedOfferData(data, processData, loadAllData);
        },
        (error) => {
          // console.log('error');
          this.dialogRef.componentInstance.errorFromServer();
        }
      );
    // }
  }

  processSavedOfferData(data, processData, loadAllData?) {
    const product = data.product;

    if (processData) {
      const fieldArray = [];

      for (const key in data.fields) {
        if (data.fields.hasOwnProperty(key)) {
          fieldArray.push(data.fields[key]);
        }
      }

      fieldArray.sort(this.compare);

      const firstForm = {};
      const fields = data.fields;

      for (let i = 0; i < fieldArray.length; i++) {
        const value = fieldArray[i];

        if (value.id === 1) {
          // firstForm[value.id] = new Date();
          if (loadAllData) {
            firstForm[value.id] = moment(value.value);
          } else {
            firstForm[value.id] = moment();
          }
        } else {
          if (value.valueType === 'number') {
            const numberPattern = new RegExp('^[0-9]+(,[0-9]*)?$');
            // let parsedValue = parseInt(value.value);
            if (numberPattern.test(value.value)) {
              firstForm[value.id] = value.value;
            }
          } else if (value.valueType === ValueTypeEnum.date && value.value) {
            const splitted = value.value.split(' ');
            const day = parseInt(
              splitted[0].substring(0, splitted[0].length - 1)
            );
            const month =
              parseInt(splitted[1].substring(0, splitted[1].length - 1)) - 1;
            const year = parseInt(splitted[2]);

            const m = moment();
            m.year(year);
            m.month(month);
            m.date(day);
            firstForm[value.id] = m;
          } else if (value.valueType === ValueTypeEnum.calendar) {
            firstForm[value.id] = moment(value.value);
          } else if (
            value.valueType === 'year' ||
            value.valueType === 'vehicle-brand' ||
            value.valueType === 'vehicle-model' ||
            value.valueType === 'city'
          ) {
            firstForm[value.id] = +value.value;
          } else {
            firstForm[value.id] = value.value;
          }
        }
      }
      this.firstFormData = firstForm;
    }
    this.state.next(SAVED_OFFER_LOADED);
    setTimeout(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
        this.dialogRef = undefined;
      }
    }, 1000);
    window.location.href = 'https://test-api.poistuj.sk/ponuky/'+product.id+'/'+this.savedOfferNumber;
    // this.router.navigate([
    //   loadAllData
    //     ? 'p/' +
    //       product.slug +
    //       '/' +
    //       product.id +
    //       '/' +
    //       this.savedOfferNumber +
    //       '/s65d4'
    //     : 'p/' + product.slug + '/' + product.id + '/' + this.savedOfferNumber,
    // ]);
  }

  compare(a, b) {
    if (a.fieldOrder < b.fieldOrder) {
      return -1;
    } else if (a.fieldOrder > b.fieldOrder) {
      return 1;
    } else {
      return 0;
    }
  }

  getFirstFormData() {
    return this.firstFormData;
  }
}
