import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesService } from '../../services';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-products-menu',
  templateUrl: './products-menu.component.html',
  styleUrls: ['./products-menu.component.scss']
})
export class ProductsMenuComponent implements OnInit {
  productId;
  products: Array<any> = [];
  category;
  showMenu: boolean = false;

  constructor(
    private router: Router,
    private categoriesService: CategoriesService,
    private productService: ProductService
  ) {
    this.categoriesService.selectedCategoryChange.subscribe(category => {
      if (category) {
        this.category = category;
        this.products = category.products;
      }
    });
    this.categoriesService.showProductsSubmenuChange.subscribe(show => {
      this.showMenu = show;
    });
    this.productService.productChange.subscribe((product) => {       
        if (product) {
          this.productId = product.id;
        }
    });
  }

  ngOnInit() {
  }

  closeSubmenu() {
    this.categoriesService.hideSubmenu();
  }

  productClicked(product) {
    this.categoriesService.hideSubmenu();
    this.router.navigate(['p/'+product.slug+'/'+product.id]);
  }

}
