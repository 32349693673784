import {Action, createReducer, on} from '@ngrx/store';

import {setFirstStepFields, setThirdStepFields, setThirdStepFieldsDefaultValue} from '../actions/poistuj.actions';
import {FirstStepFieldsState} from './first-step-fields.reducer';

const initialState: FirstStepFieldsState = {
    fields: []
};

const thirdStepFieldsReducer = createReducer(
    initialState,
    on(setThirdStepFields, (state, { fields }) => {
        return {
            ...state,
            fields
        };
    }),
    
);

export function thirdStepFieldsReducerFunction(
    state: FirstStepFieldsState | undefined,
    action: Action
) {
    return thirdStepFieldsReducer(state, action);
}
