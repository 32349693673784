import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {  

  constructor() {}

  scrollToElementById(idOfElement) {
      
      const element = document.getElementById(idOfElement);
      if (!!element && element.scrollIntoView) {
        //console.log('SCROLLING TO '+idOfElement);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        //document.body.scrollTop = document.documentElement.scrollTop = element.scrollTop;
        element.scrollIntoView({ behavior: "smooth" });                                
      } else {
     //   console.log('NO element with id "' + idOfElement + '" was found.');
      }
  }

  scrollToTop() {
    /*console.log('SCROLL TO TOP');
    document.body.scrollTop = document.documentElement.scrollTop = 0;*/
    window.scrollTo(0, 0);
  }

}
