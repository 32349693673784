<div class="top-submenu" *ngIf="showMenu">
  <div class="submenu">
    <!--<div class="submenu-back"><mat-icon>keyboard_backspace</mat-icon> <span>Späť</span></div>-->
    <h2><span [innerHtml]="category?.pageTitle"></span><!-- <span class="submenu-backlink" (click)="closeSubmenu()">Zavrieť</span>--></h2>
    <a class="product-card" [class.active]="product.id==productId" *ngFor="let product of products" (click)="productClicked(product)">
      <div class="card-image"><img src="assets/{{ product.iconBlack }}"></div>
      <div class="card-image card-image-hover"><img src="assets/{{ product.iconWhite }}"></div>
      <div class="card-label">{{ product.title }}</div>
    </a>
    <ng-container *ngIf="category.content">
      <h1>{{ category.title }}</h1>
      <div class="category-content" [innerHTML]="category.content">
      </div>
    </ng-container>
  </div>
</div>