import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CategoriesService {
    private categories: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    categoriesChange: Observable<any>;
    
    private selectedCategory: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedCategoryChange: Observable<any>;

    private showProductsSubmenu: BehaviorSubject<any> = new BehaviorSubject(false);
    showProductsSubmenuChange: Observable<any>;

    constructor(      
        private apiService: ApiService,
        private sanitizer: DomSanitizer,

      ) {
        this.categoriesChange = this.categories.asObservable();
        this.selectedCategoryChange = this.selectedCategory.asObservable();
        this.showProductsSubmenuChange = this.showProductsSubmenu.asObservable();
    }

    hideSubmenu() {
        this.showProductsSubmenu.next(false);
    }

    showSubmenu() {
        this.showProductsSubmenu.next(true);
    }

    load() {
        if (this.categories.getValue().length==0) {
            this.apiService.get('/category/menu')                        
                        .subscribe(categories=>this.setCategories(categories));
        }
    }

    getCategoryObservable(slug: string):Observable<any> {        
        return this.categoriesChange.pipe(map(categories=>categories.find(c=>c.slug==slug)));
    }

    private emitCategoriesChange(categories):void {    
        this.categories.next(categories);
    }

    setCategories(categories) {
        this.emitCategoriesChange(categories.map((category:any)=>{
            category.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(category.content);
            return category;
        }));
    }

    getCategories() {        
        return this.categories;
    }

    getCategoriesByIds(ids) {
        return this.categories.getValue().filter(category=>ids.indexOf(category['id'])>-1);
    }

    selectCategoryById(categoryId) {
        let categories = this.categories.getValue().filter(cat=>cat.id==categoryId);
        if (categories.length>0) {
            this.selectCategory(categories[0]);
        } else {
            return false;
        }
    } 

    selectCategory(category) {
        if (this.showProductsSubmenu.getValue()===false || this.selectedCategory.getValue()===null || this.selectedCategory.getValue().id!==category.id) {
            this.selectedCategory.next(category);
            this.showSubmenu();
        } else {
            this.selectedCategory.next(null);
            this.hideSubmenu();
        }
    }

    getCategoryBySlug(slug: string) {
        const categories = this.categories.getValue().filter(cat=>cat.slug==slug);
        if (categories.length>0) {
            return categories[0];
        } else {
            return null;
        }
    }

    getCategory(id) {
        let categories = this.categories.getValue().filter(cat=>cat.id==id);
        if (categories.length>0) {
            return categories[0];
        } else {
            return null;
        }
    }
}
