import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {Product} from 'app/models/product';
import {PayFrequencyEnum} from '../../models/pay-frequency.enum';

import {PriceOffer} from '../../models/price-offer';

/** CUSTOM **/
export const loadNewPriceOffers = createAction(
  '[PriceOffer/API] Load NEW PriceOffers', 
  props<{ product: Product }>()
);

export const loadApiPriceOffer = createAction(
  '[PriceOffer/API] Load API PriceOffer',
  props<{ productId: number, categoryId: number, insCompanyId: number, insCompanyName: string, isProduction: boolean, data: any, isAxa?: boolean, axaOffers?: any[]}>()
);

export const addApiPriceOfferError = createAction(
  '[PriceOffer/API] add API PRICE OFFER ERROR',
  props<{ insCompanyId: number, error: any }>()
);

export const confirmAxaOffers = createAction(
  '[PriceOffer/API] Confirm AXA offers',
  props<{ product: any, formData: any }>()
);

export const updateAxaOffer = createAction(
    '[PriceOffer/API] Update AXA offer',
    props<{ offer: PriceOffer, product: any, formData: any }>()
);

export const updateAxaOfferSuccess = createAction(
  '[PriceOffer/API] Update AXA offer SUCCESS',
  props<{ priceOffer: PriceOffer }>()
);

/** ADAPTER **/
export const loadPriceOffers = createAction(
  '[PriceOffer/API] Load PriceOffers', 
  props<{ priceOffers: PriceOffer[] }>()
);

export const addPriceOffer = createAction(
  '[PriceOffer/API] Add PriceOffer',
  props<{ priceOffer: PriceOffer }>()
);

export const upsertPriceOffer = createAction(
  '[PriceOffer/API] Upsert PriceOffer',
  props<{ priceOffer: PriceOffer }>()
);

export const addPriceOffers = createAction(
  '[PriceOffer/API] Add PriceOffers',
  props<{ priceOffers: PriceOffer[] }>()
);

export const upsertPriceOffers = createAction(
  '[PriceOffer/API] Upsert PriceOffers',
  props<{ priceOffers: PriceOffer[] }>()
);

export const updatePriceOffer = createAction(
  '[PriceOffer/API] Update PriceOffer',
  props<{ priceOffer: Update<PriceOffer> }>()
);

export const updatePriceOffers = createAction(
  '[PriceOffer/API] Update PriceOffers',
  props<{ priceOffers: Update<PriceOffer>[] }>()
);

export const deletePriceOffer = createAction(
  '[PriceOffer/API] Delete PriceOffer',
  props<{ id: string }>()
);

export const setPriceOfferPaymentFrequency = createAction(
    '[PriceOffer/API] Set PriceOffer Payment Frequency',
    props<{ id: number, paymentFrequncy: PayFrequencyEnum }>()
);

export const deletePriceOffers = createAction(
  '[PriceOffer/API] Delete PriceOffers',
  props<{ ids: string[] }>()
);

export const clearPriceOffers = createAction(
  '[PriceOffer/API] Clear PriceOffers'
);

export const clearPriceOffersLoadingCount = createAction(
    '[PriceOffer/API] Clear PriceOffers Loading Count'
);
