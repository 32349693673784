import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, take} from 'rxjs/operators';
import { PriceOfferStatusEnum } from 'app/models/price-offer-status.enum';
import {SavedOfferService} from "./saved-offer.service";

@Injectable()
export class ApiOffersService {
  pzpApis = [
    {
      name: 'pzpaxa',
      products: [1, 4]
    },
    {
      name: 'pzpgroupama',
      products: [1]
    },
    /*{
      name: 'pzpallianz',
      products: [1]
    },*/
    {
      name: 'pzpwus',
      products: [1,2,3,4]
    },
    {
      name: 'pzpunion',
      products: [1,2,3,4,5,6]
    }
  ];

  private apisLoadingCount: BehaviorSubject<number> = new BehaviorSubject(-1);
  apisLoadingCountChange: Observable<any>;

  private offers: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  offersChange: Observable<any>;

  private errors: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  errorsChange: Observable<any>;

  public savedOffer: BehaviorSubject<number> = new BehaviorSubject(null);
  savedOfferChange: Observable<any>;

  constructor(
    private apiService: ApiService,
    private savedOfferService: SavedOfferService
  ) {
    this.offersChange = this.offers.asObservable();
    this.errorsChange = this.errors.asObservable();
    this.apisLoadingCountChange = this.apisLoadingCount.asObservable();
    this.savedOfferChange = this.savedOffer.asObservable();
  }

  clearOffers() {
    this.offers.next([]);
    this.apisLoadingCount.next(0);
  }

  loadOffers( product, category, formData ) {
      let apiOffersToConfirm = [];
      let savedOffer = null;
      this.savedOffer.next(null);
      this.savedOfferChange.subscribe(savedOffer => {
        if (savedOffer) {
          if (apiOffersToConfirm.length>0) {
            let apiOffer = apiOffersToConfirm.pop();
            while (apiOffer) {
             // console.log('**saving');
              this.saveApiOffer(savedOffer, apiOffer );
              apiOffer = apiOffersToConfirm.pop();
            }
          }
        }
      });
      this.offers.next([]);
      if (category.id==1) {
          // PZP
          //if (product.id==1) {
            this.getPzpOffers(product, formData, (offers) => {
              if (this.savedOffer.getValue()==null) {
                apiOffersToConfirm = [...apiOffersToConfirm, ...offers];
              } else {
                offers.forEach(offer => {
                  this.saveApiOffer(this.savedOffer.getValue(), {
                    title: offer.title,
                    price: offer.price,
                    priceHalf: offer.priceHalf,
                    priceThird: offer.priceThird,
                    apiErrorId: offer.apiErrorId
                  });
                });
              }
            });
          //}
      } else {
        this.apisLoadingCount.next(0);
      }
  }

  /**
   * GET PZP OFFERS
   */
  getPzpOffers(product, formData, callback) {
    this.apisLoadingCount.next(this.pzpApis.length);

    for(let i = 0; i < this.pzpApis.length; i++) {
      const api = this.pzpApis[i];

      if (api.products.indexOf(product.id)>-1) {
        let apiFinished = false;

        this[api.name](formData, product).subscribe((data) =>  {
          if (!apiFinished) {
            apiFinished = true;
            if (data['errors'] && data['errors'].length > 0) {
              this.errors.next(data['errors']);
            } else {
              if (data.name === 'pzpaxa') {
                this.addOffers(data['offers']
                    .map(offer => {
                      offer.priceStatus = PriceOfferStatusEnum.AXA_CONFIRM_REQUIRED;
                      return offer;
                    }));
              } else {
                this.addOffers(data['offers']);
              }

              callback(data['offers']);
            }
            this.apisLoadingCount.next(this.apisLoadingCount.getValue()-1);
          }
        });
        setTimeout(()=>{
          if (!apiFinished) {
            apiFinished = true;
            this.apisLoadingCount.next(this.apisLoadingCount.getValue()-1);
          }
        }, 60000); //30000 //14000);
      } else {
        this.apisLoadingCount.next(this.apisLoadingCount.getValue()-1);
      }
    }
  }

  updateOffers(offers) {
    console.log('update offers', offers);
    const newOffers = [...this.offers.getValue().map(offer=>{
      console.log(offer);
      const foundOffer = offers.find(of=>of.insCompanyId === offer.insCompanyId);
      return foundOffer || offer;
    })];
    this.offers.next(newOffers);
  }

  addOffers(offers) {
    let newOffers = this.offers.getValue();
    offers.forEach(offer => {
      newOffers.push(offer);
    });
    this.offers.next(newOffers);
  }

  pzpwus(formData, product) {
    return this.apiService.post('/api/wus/pzp-offers', {
        data: formData,
        product: product.id
    }).pipe(map(data=>{
      try {
        const res = data;
        res.name = 'pzpwus';
        return res;
      } catch(e) {
        return {
          offers: [],
          errors: []
        };
      }
    }));
  }

  pzpaxa(formData, product) {
    return this.apiService.post('/api/axa/pzp-offers', {
        data: formData,
        product: product.id
    }).pipe(map(data=>{
      try {
        const res = data;
        res.name = 'pzpaxa';
        return res;
      } catch(e) {
        return {
          offers: [],
          errors: [],
        };
      }
    }));
  }

  pzpgroupama(formData, product) {
    return this.apiService.post('/api/groupama/pzp-offers', {
        data: formData,
        product: product.id
    }).pipe(map(data=>{
      try {
        const res = data;
        res.name = 'pzpgroupama';
        return res;
      } catch(e) {
        return {
          offers: [],
          errors: []
        };
      }
    }));
  }

  pzpallianz(formData, product) {
    return this.apiService.post('/api/allianz/pzp-offers', {
        data: formData,
        product: product.id
    }).pipe(map(data=>{
      try {
        const res = data;
        res.name = 'pzpallianz';
        return res;
      } catch(e) {
        return {
          offers: [],
          errors: []
        };
      }
    }));
  }

  pzpunion(formData, product) {
    return this.apiService.post('/api/union/pzp-offers', {
        data: formData,
        product: product.id
    }).pipe(map(data=>{
      try {
        const res = data;
        res.name = 'pzpunion';
        return res;
      } catch(e) {
        return {
          offers: [],
          errors: []
        };
      }
    }));
  }

  saveApiOffer(_savedOfferNunber, offerData: { title: string, price: number, priceHalf: number, priceThird: number, apiErrorId: number }) {
    const interval = setInterval(() => {
      const savedOffer = this.savedOfferService.getSavedOffer();
      if (savedOffer) {
        clearInterval(interval);
        this.apiService.post('/api/offer/add-api', {
          savedOfferId: savedOffer.number,
          data: offerData
        }).pipe(take(1), map(data => {
          try {
            const res = data;
            return res;
          } catch (e) {
            return {
              offers: [],
              errors: []
            };
          }
        })).subscribe(res => console.log(res));
      }
    });
  }

  setSavedOffer(savedOfferNumber) {
    this.savedOffer.next(savedOfferNumber);
  }

}
