<div class="static-page-content">
    

    <h1>Stránka sa nenašla</h1>
    <h3 class="text-center">Chyba 404</h3>

    <p class="text-center">
      Požadovaná stránka neexistuje.<br/>
      Prosím skontrolujte adresu stránky.
    </p>

    <p class="text-center">
      <a href="/">Prejsť na domovskú stránku</a>
    </p>

</div>