import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ModalInfoComponent } from "app/components/modal-info/modal-info.component";

@Injectable()
export class ModalNotifyService {

  constructor(
    public dialog: MatDialog
  ) { }

  showSimpleInfo(message) {
    this.launchModal(message, 'simple');
  }

  launchModal(message, type) {
    let dialogRef = this.dialog.open(ModalInfoComponent, {
        width: '600px',
        data: {
            message: message,
            type: type
        }
    });
  }

}
