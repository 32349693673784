import {ActionReducerMap, MetaReducer} from '@ngrx/store';

import {environment} from '../../../environments/environment';
import {autocompleteItemsReducerFunction, AutocompleteItemsState} from './autocomplete-items.reducer';
import {extraFieldsReducerFunction} from './extra-fields.reducer';
import {firstStepFieldsReducerFunction, FirstStepFieldsState} from './first-step-fields.reducer';
import {formValuesReducerFunction, FormValuesState} from './form-values.reducer';
import {priceOffersReducer, PriceOffersState} from './price-offer.reducer';
import {thirdStepFieldsReducerFunction} from './third-step-fields.reducer';

export interface State {
  priceOffers: PriceOffersState;
  firstStepFields: FirstStepFieldsState;
  thirdStepFields: FirstStepFieldsState;
  extraFields: FirstStepFieldsState;
  formValues: FormValuesState;
  autocompleteItems: AutocompleteItemsState;
}

export const reducers: ActionReducerMap<State> = {
  priceOffers: priceOffersReducer,
  firstStepFields: firstStepFieldsReducerFunction,
  thirdStepFields: thirdStepFieldsReducerFunction,
  extraFields: extraFieldsReducerFunction,
  formValues: formValuesReducerFunction,
  autocompleteItems: autocompleteItemsReducerFunction,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
