import { Injectable } from '@angular/core';
import {StoreFacade} from '../+state/store.facade';
import {processFieldValueForServer} from '../forms/process-field-value-for-server';
import {FieldDto} from '../models/dto/field-dto';

import { ApiService } from './api.service';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { FirstStepService } from './first-step.service';
import { ThirdStepService } from './third-step.service';
import { OffersService } from './offers.service';
import { SavedOfferService } from './saved-offer.service';
import { DomSanitizer } from "@angular/platform-browser";
import {map, take} from 'rxjs/operators';

@Injectable()
export class ContractPreviewService {
    private contractPreviewData: BehaviorSubject<any> = new BehaviorSubject(null);
    contractPreviewDataChange: Observable<any>;

    constructor(
        private apiService: ApiService,
        private firstStepService: FirstStepService,
        private thirdStepService: ThirdStepService,
        private offersService: OffersService,
        private savedOfferService: SavedOfferService,
        private _sanitizer: DomSanitizer,
        private storeFacade: StoreFacade
    ) { 
        this.contractPreviewDataChange = this.contractPreviewData.asObservable();
    }

    clear() {
        this.contractPreviewData.next(null);
    }

    getAsObject() {
        return this.contractPreviewData.getValue();
    }

    /**     
     * @param parameters all parameters from first and third step
     * @param chosenOffer
     * @param savedOfferId if it is based on savedOffer
     */
    load() {
        combineLatest(this.storeFacade.formValues$, this.storeFacade.thirdStepfields$, this.storeFacade.firstFormFieldsAsArray$, this.storeFacade.extrafields$)
            .pipe(take(1))
            .subscribe(([formValues, thirdStepFields, firstStepFields, extraFields]) => {
                const parameters = {};

                const formlyFields = this.firstStepService.getFormlyFields();
                const formlyFieldsThirdStep = this.thirdStepService.getFormlyFields();

                firstStepFields.forEach((field: FieldDto) => {
                    if (formValues.hasOwnProperty(field.code)) {
                        parameters[field.code] = processFieldValueForServer(field, formValues[field.code], formlyFields);
                    }
                });

                extraFields.forEach((field: FieldDto) => {
                    if (formValues.hasOwnProperty(field.code)) {
                        parameters[field.code] = formValues[field.code];
                    }
                });

                thirdStepFields.forEach((field: FieldDto) => {
                    if (formValues.hasOwnProperty(field.code)) {
                        parameters[field.code] = processFieldValueForServer(field, formValues[field.code], formlyFieldsThirdStep);
                    }
                });

            //});
       // this.storeFacade.formValues$.pipe(take(1))
         //   .subscribe((parameters) => {
                const chosenOffer = this.offersService.getChosenOffer();
                const savedOfferId = this.savedOfferService.getSavedOffer();
                this.apiService.post('/getcontractpreview/' + chosenOffer.id, {
                    parameters: parameters,
                    chosenOffer: chosenOffer,
                    savedOfferId: savedOfferId
                })
                    .pipe(take(1))
                    .subscribe(data => {

                        data.contract.preview = this._sanitizer.bypassSecurityTrustHtml(data.contract.preview);

                        data.contractApiErrors = [];

                        if (data.error==1) {
                            data.contractApiErrors = data.errors;
                        }

                        //this.loadingContractPreview = false;
                        //this.contractPreview = data.contract.preview;


                        data.contractPreviewAgreements = [];
                        if (data.agreements) {
                            data.agreements.forEach(agreement => {
                                data.contractPreviewAgreements.push({
                                    id: agreement.id,
                                    is_required: agreement.is_required,
                                    is_shown: agreement.is_shown,
                                    content: agreement.content,
                                    preview: this._sanitizer.bypassSecurityTrustHtml(agreement.preview),
                                    code: agreement.code
                                });
                            });
                        }

                        data.contractPreview = {};
                        data.contractPreviewAgreements.forEach(agreement => {
                            data.contractPreview[agreement.id] = false;
                        });

                        this.contractPreviewData.next(data);
                        /*
                        this.contractApiErrors = [];

                        if (data.error==1) {
                            console.log('API ERROR!!!');
                            this.contractApiErrors = data.errors;
                        }

                        this.loadingContractPreview = false;
                        this.contractPreview = data.contract.preview;


                        this.contractPreviewAgreements = [];
                        data.agreements.forEach(agreement => {
                            this.contractPreviewAgreements.push({
                                id: agreement.id,
                                is_required: agreement.is_required,
                                is_shown: agreement.is_shown,
                                content: agreement.content,
                                preview: $sce.trustAsHtml(agreement.preview)
                            });
                        });

                        this.contractPreviewAgreements.forEach(agreement => {
                            this.contractPreview[agreement.id] = false;
                        });*/
                    });
            });

    }
}
