import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-call-me',
  templateUrl: './call-me.component.html',
  styleUrls: ['./call-me.component.scss']
})
export class CallMeComponent implements OnInit {
  loading = true;
  error = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CallMeComponent>
  ) {

  }

  ngOnInit() {
  }

  finished() {
    this.loading = false;
  }

  errorFromServer() {
    this.loading = false;
    this.error = true;
  }

  close() {
    this.dialogRef.close();
  }


}
