import { CategoriesService, LayoutService, DeviceService, ScrollService } from "app/services";
import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable ,  Subscription } from "rxjs";
import { AfterViewInit } from "@angular/core";
import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChooseProductModalComponent } from 'app/components/choose-product-modal/choose-product-modal.component';

@Component({
    selector: 'app-category',
    templateUrl: './category-new.component.html'    
  })
  export class CategoryComponent implements OnInit, AfterViewInit {
    category$: Observable<any>;
    sub: Subscription;
    products: any[];

    constructor(
        private categoriesService: CategoriesService,
        private route: ActivatedRoute,
        private layoutService: LayoutService,
        private deviceService: DeviceService,
        private scrollService: ScrollService,
        private dialog: MatDialog,
        private elementRef: ElementRef
    ){
      layoutService.setBodyClasses(['product']);
    }

    ngOnInit() {
      this.category$ = this.route.paramMap.pipe(
          switchMap((params: ParamMap) =>
            this.categoriesService.getCategoryObservable(params.get('slug'))
          ));
    }

    ngAfterViewInit() {      
      this.sub = this.category$.subscribe(cat=>{        
        if (cat) {
          this.products = cat.products;
          setTimeout(() => {
            this.prepareEvents(cat.title);
          }, 2000);
        }        
        if (cat && this.deviceService.isMobile()) {
          setTimeout(() => {
            this.scrollService.scrollToElementById('products-menu');
          }, 200);          
        }
      });
    }

    ngOnDestroy() {
      if (this.sub) {
        this.sub.unsubscribe();
      }
    }

    prepareEvents(title: string) {      
      Array.from(document.getElementsByClassName('click-button')).forEach(
          (element, index, array) => {                            
              element.addEventListener('click', ()=>{
                this.showProductsModal(title);
              });
          }
      );

      /*[...document.getElementsByClassName('click-button')].forEach(()=>{
        alert('click');
      });*/
      
      /*.forEach(element => {
          element.addEventListener('click', ()=>{
            alert('click');
          });
      });*/
    }

    showProductsModal(title: string) {      
      let dialogRef = this.dialog.open(ChooseProductModalComponent, {
        panelClass: 'grey-modal'
      });
      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.products = this.products;
      
    }
  }
