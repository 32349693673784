<!--
<div class="loader-cont">  
  <img class="loader-rotating" src="assets/images/znacka-small.png">  
  <p>{{ text }}</p>
</div>
-->
<div class="progress-spinner">
  <div class="spinner-cont">
    <mat-spinner strokeWidth="2"></mat-spinner>
  </div>
  <div class="image">
    <img src="assets/images/znacka-small.png">  
  </div>
  <p class="loader-text">{{ text }}</p>
</div>
