<div class="close-modal" (click)="close()"><img src="assets/images/x.svg"></div>

<h3>Vyberte si typ poistenia pre pokračovanie</h3>

<div class="home-categories">
    <a class="category-card pzp" routerLink="/kat/pzp" (click)="close()">
        <p>Chcem uzatvoriť</p>
        <h3>Povinné zmluvné poistenie</h3>
        <div class="image">
            <img src="assets/images/pzp-img.png">
        </div>
    </a>
    <a class="category-card" routerLink="/kat/zodpovednost" (click)="close()">
        <p>Chcem uzatvoriť</p>
        <h3>Zodpovednosť zamestnanca</h3>
        <div class="image">
            <img src="assets/images/zodpovednost-zam-img.png">
        </div>
    </a>
    <a class="category-card uraz" routerLink="/kat/sedadla" (click)="close()">
        <p>Chcem uzatvoriť</p>
        <h3>Poistenie úrazu vo vozidle</h3>
        <div class="image">
            <img src="assets/images/poistenie-urazu-img.png">
        </div>
    </a>
    <!--<a class="category-card ee" routerLink="/kat/ee" (click)="close()">
        <p>Chcem uzatvoriť</p>
        <h3>Asistenčné služby</h3>
        <div class="image">
            <img src="assets/images/asist-sluzby-img.png">
        </div>
    </a>-->
</div>