import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {DebugPriceOffer, DebugPriceOfferStatus} from 'app/models/debug-price-offer';
import {PriceOffer} from 'app/models/price-offer';
import {PriceOfferStatusEnum} from 'app/models/price-offer-status.enum';
import _ from 'underscore';

import * as PriceOfferActions from '../actions/price-offer.actions';
import {loadNewPriceOffers} from '../actions/price-offer.actions';

export interface PriceOffersState extends EntityState<PriceOffer> {
  // additional entities state properties
  debugPriceOffers: DebugPriceOffer[];
  loadingOffers: boolean;
  offersLoadingCount: number;
}

export const adapter: EntityAdapter<PriceOffer> = createEntityAdapter<
  PriceOffer
>();

export const priceOffersInitialState: PriceOffersState = adapter.getInitialState(
  {
    // additional entity state properties
    debugPriceOffers: [],
    loadingOffers: false,
    offersLoadingCount: 0,
  }
);

const priceOfferReducer = createReducer(
  priceOffersInitialState,
  on(loadNewPriceOffers, (state, action) => {
    return {
      ...state,
      offersLoadingCount: 1,
      loadingOffers: true,
    };
  }),
  on(PriceOfferActions.addPriceOffer, (state, action) => {
    if (state.loadingOffers) {
      adapter.addOne(action.priceOffer, state)
    } else {
      return {
        ...state
      };
    }
    }),
  on(PriceOfferActions.upsertPriceOffer, (state, action) =>
    adapter.upsertOne(action.priceOffer, state)
  ),
  on(PriceOfferActions.setPriceOfferPaymentFrequency, (state, action) => {
    return adapter.updateOne({
      id: action.id,
      changes: { payFrequency: action.paymentFrequncy }
    }, state);
  }),
  on(PriceOfferActions.addPriceOffers, (state, action) => {

    if (state.loadingOffers) {

      let debugPriceOffers: DebugPriceOffer[] = state.debugPriceOffers.slice();
      const existingOffers = Object.values(state.entities);

      action.priceOffers.forEach((priceOffer: PriceOffer) => {
        debugPriceOffers = debugPriceOffers.map(
            (debugPriceOffer: DebugPriceOffer) =>
                debugPriceOffer.availableApi.insCompanyId === priceOffer.insCompanyId
                    ? {
                      ...debugPriceOffer,
                      status: DebugPriceOfferStatus.offerReveived
                    }
                    : debugPriceOffer
        );
      });

      return adapter.addMany(action.priceOffers.map((offer: PriceOffer) => {
        const exists = existingOffers.find((existing: PriceOffer) => existing.insCompanyId === offer.insCompanyId);
        if (exists) {
          return {
            ...offer,
            payFrequency: exists.payFrequency
          };
        }
        return offer;
      }), {
        ...state,
        debugPriceOffers: debugPriceOffers,
        offersLoadingCount: state.offersLoadingCount > 0 ? state.offersLoadingCount - 1 : state.offersLoadingCount,
      });

    } else {
      return {
        ...state
      };
    }
  }),
  on(PriceOfferActions.upsertPriceOffers, (state, action) =>
    adapter.upsertMany(action.priceOffers, state)
  ),
  on(PriceOfferActions.updatePriceOffer, (state, action) =>
    adapter.updateOne(action.priceOffer, state)
  ),
  on(PriceOfferActions.updatePriceOffers, (state, action) =>
    adapter.updateMany(action.priceOffers, state)
  ),
  on(PriceOfferActions.deletePriceOffer, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(PriceOfferActions.deletePriceOffers, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(PriceOfferActions.loadPriceOffers, (state, action) =>
    adapter.addAll(action.priceOffers, state)
  ),
  on(PriceOfferActions.clearPriceOffers, (state) => adapter.removeAll({
    ...state,
    debugPriceOffers: []
  })),
  on(PriceOfferActions.loadApiPriceOffer, (state, action) => {
    const debugPriceOffers = state.debugPriceOffers.slice();
    debugPriceOffers.push({
      availableApi: {
        insCompanyId: action.insCompanyId,
        insCompanyName: action.insCompanyName,
        isProduction: action.isProduction
      },
      status: DebugPriceOfferStatus.loadingFromServer
    });
    return {
      ...state,
      debugPriceOffers: debugPriceOffers,
      offersLoadingCount: state.offersLoadingCount + 1,
    };
  }),
  on(PriceOfferActions.clearPriceOffersLoadingCount, (state) => {
    return {
      ...state,
      loadingOffers: false,
      offersLoadingCount: 0,
    };
  }),
  on(PriceOfferActions.addApiPriceOfferError, (state, action) => {
    const debugPriceOffers = state.debugPriceOffers.map(
      (debugPriceOffer: DebugPriceOffer) =>
        debugPriceOffer.availableApi.insCompanyId === action.insCompanyId
          ? {
              ...debugPriceOffer,
              status: DebugPriceOfferStatus.error
            }
          : debugPriceOffer
    );
    return {
      ...state,
      debugPriceOffers: debugPriceOffers,
      offersLoadingCount: state.offersLoadingCount > 0 ? state.offersLoadingCount - 1 : state.offersLoadingCount
    };
  }),
  on(PriceOfferActions.updateAxaOffer, (state, action) => {
    return adapter.updateOne({
      id: action.offer.id,
      changes: { priceStatus: PriceOfferStatusEnum.LOADING }
    }, state);
  }),
  on(PriceOfferActions.updateAxaOfferSuccess, (state, action) => {
    const existingOffer = _.values(state.entities).find(offer => offer.insCompanyId === action.priceOffer.insCompanyId);
    if (existingOffer) {
      const removedState = adapter.removeOne(existingOffer.id, state);
      return adapter.addOne({
        ...action.priceOffer,
        priceStatus: PriceOfferStatusEnum.OK
      }, removedState);
    } else {
      return {
        ...state
      };
    }
  }),
);

export function priceOffersReducer(
  state: PriceOffersState | undefined,
  action: Action
) {
  return priceOfferReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
