import {createAction, props} from '@ngrx/store';

import {AutocompleteItemsEnum} from '../../models/autocomplete-items.enum';
import {FieldDto} from '../../models/dto/field-dto';

export const loadAutocompleteItems = createAction(
    '[Poistuj] Load autocomplete items',
    props<{ itemsCode: AutocompleteItemsEnum }>()
);

export const loadAutocompleteItemsFinished = createAction(
    '[Poistuj] Load autocomplete items finished',
    props<{ itemsCode: AutocompleteItemsEnum, items: any[] }>()
);

export const setFirstStepFields = createAction(
    '[Poistuj] Set First Step Fields',
    props<{ fields: FieldDto[] }>()
);

export const setThirdStepFieldsDefaultValue = createAction(
    '[Poistuj] Set Third Step Fields Default Value',
    props<{ defaultValueFields: { code: string, value: any }[] }>()
);

export const setExtraFields = createAction(
    '[Poistuj] Set Extra Fields',
    props<{ fields: FieldDto[] }>()
);

export const setThirdStepFields = createAction(
    '[Poistuj] Set Third Step Fields',
    props<{ fields: FieldDto[] }>()
);
