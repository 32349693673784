import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StoreFacade } from '../../+state/store.facade';

@Component({
  selector: 'app-loading-offers-modal',
  templateUrl: './loading-offers.component.html',
  styleUrls: ['./loading-offers.component.scss']
})
export class LoadingOffersComponent implements OnDestroy {
  offersCount$ = this.storeFacade.offersLoadingCount$;
  sub;

  constructor(
    public dialogRef: MatDialogRef<LoadingOffersComponent>,
    private storeFacade: StoreFacade
  ) {
    this.sub = this.offersCount$.subscribe((offersCount: number) => {
      if (offersCount === 0) { this.close(); }
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getText(count: number) {
    return (
        'Pripravujem ponuku...'
    );
    /*return (
      'Čakáme ešte na ' +
      count + ' ' +
      (count > 4 || count === 0 ? 'ponúk' : count > 1 ? 'ponuky' : 'ponuku') +
      '.'
    );*/
  }
}
