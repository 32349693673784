import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {GtagModule} from 'angular-gtag';
import {OfflineComponent} from 'app/components/offline/offline.component';
import {CategoryComponent} from 'app/pages/category/category.component';
import {ContactComponent} from 'app/pages/contact/contact.component';

import {environment} from '../environments/environment';
import {loadAutocompleteItems} from './+state/actions/poistuj.actions';
import {AppEffects} from './+state/effects/app.effects';
import {PriceOffersEffects} from './+state/effects/price-offers.effects';
import {metaReducers, reducers} from './+state/reducers';
import {StoreFacade} from './+state/store.facade';
import {AppComponent} from './app.component';
import {ProductsMenuComponent} from './components';
import {ChooseCategoryModalComponent} from './components/choose-category-modal/choose-category-modal.component';
import {ChooseProductModalComponent} from './components/choose-product-modal/choose-product-modal.component';
import {ConfirmChangesDialogComponent} from './components/confirm-changes-dialog/confirm-changes-dialog.component';
import {LoaderComponent} from './components/loader/loader.component';
import {LoadingOffersComponent} from './components/loading-offers/loading-offers.component';
import {ModalInfoSpzComponent} from './components/modal-info-spz/modal-info-spz.component';
import {ModalInfoComponent} from './components/modal-info/modal-info.component';
import {SavedOfferDialogComponent} from './components/saved-offer-dialog/saved-offer-dialog.component';
import {ConfirmChangesGuardGuard} from './guards/confirm-changes-guard.guard';
import {AutocompleteItemsEnum} from './models/autocomplete-items.enum';
import {ProductComponent} from './pages';
import {DocumentPageComponent} from './pages/document-page/document-page.component';
import {HomepageComponent} from './pages/homepage/homepage.component';
import {InsurencePageComponent} from './pages/insurence-page/insurence-page.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {SafetyComponent} from './pages/safety/safety.component';
import {AboutComponent} from './pages/static/about/about.component';
import {routes} from './routes';
import {
  ApiService,
  AutocompleteApiService,
  AutocompleteItemsService,
  CategoriesService,
  ContractPreviewService,
  DeviceService,
  FieldsConditionsService,
  FieldsProcessorService,
  FirstStepService,
  InsurenceService,
  LayoutService,
  ModalNotifyService,
  OffersService,
  ProductService,
  SavedOfferService,
  ScrollService,
  ThirdStepService
} from './services';
import {ApiOffersService} from './services/api-offers.service';

@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    ProductsMenuComponent,
    HomepageComponent,
    // Step1FieldsComponent,
    // Step2OffersComponent,
    // Step3FieldsComponent,
    // Step4PreviewComponent,
    // Step5FinalComponent,
    // FieldComponent,
    // TextFieldComponent,
    // ChoiceFieldComponent,
    // CalendarFieldComponent,
    // NumberFieldComponent,
    // AutocompleteFieldComponent,
    // VehicleBrandFieldComponent,
    // VehicleModelFieldComponent,
    // YearFieldComponent,
    // DateFieldComponent,
    // RadioFieldComponent,
    // CityFieldComponent,
    // HiddenFieldComponent,
    LoaderComponent,
    // StepOfferComponent,
    SavedOfferDialogComponent,
    //ContractPreviewDialogComponent,
    InsurencePageComponent,
    AboutComponent,
    //CallMeComponent,
    ModalInfoComponent,
    ConfirmChangesDialogComponent,
    DocumentPageComponent,
    //SpzComponent,
    NotFoundComponent,
    SafetyComponent,
    //TextWithExtraComponent,
    ContactComponent,
    //CallMeOfferComponent,
    //SendMeOfferComponent,
    OfflineComponent,
    CategoryComponent,
    ChooseCategoryModalComponent,
    ChooseProductModalComponent,
    //FillRequiredFieldsComponent,
    //FormGroupPanelComponent,
    //FieldWrapperComponent,
    //DateInputComponent,
    //PoistujDatepickerTypeComponent,
    //PoistujWrapperFormField,
    //SpzComponent,
    LoadingOffersComponent,
    ModalInfoSpzComponent,
    //SpzApiComponent
  ],
  entryComponents: [
    SavedOfferDialogComponent,
    ModalInfoComponent,
    //ContractPreviewDialogComponent,
    //CallMeComponent,
    //ConfirmChangesDialogComponent,
    //CallMeOfferComponent,
    //SendMeOfferComponent,
    OfflineComponent,
    ChooseCategoryModalComponent,
    ChooseProductModalComponent,
    ContactComponent,
    //FillRequiredFieldsComponent,
    //FieldWrapperComponent,
    //PoistujWrapperFormField,
    LoadingOffersComponent,
    //ModalInfoSpzComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCardModule,
    MatMomentDateModule,
    GtagModule.forRoot({ trackingId: 'UA-127205261-1', trackPageviews: true, debug: true }),
    EffectsModule.forRoot([AppEffects]),
    // FormlyMatDatepickerModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    // FormlyModule.forRoot({
    //   wrappers: [
    //     { name: 'formGroupPanel', component: FormGroupPanelComponent },
    //     { name: 'field', component: FieldWrapperComponent },
    //     {
    //       name: 'form-field',
    //       component: PoistujWrapperFormField,
    //     },
    //     {
    //       name: 'spz',
    //       component: SpzComponent,
    //     },
    //     {
    //       name: 'spz-api',
    //       component: SpzApiComponent,
    //     }
    //   ],
    //   types: [
    //     { name: 'date', component: DateInputComponent },
    //     { name: 'datepicker', component: PoistujDatepickerTypeComponent }
    //   ],
    //   validators: [{ name: 'birthdate', validation: birthNumberValidator }],
    //   validationMessages: [
    //     { name: 'birthdate', message: 'Rodné číslo nie je v správnom tvare.' }
    //   ]
    // }),
    // FormlyMaterialModule
  ],
  providers: [
    ApiService,
    CategoriesService,
    //FirstStepService,
    ProductService,
    FieldsProcessorService,
    FieldsConditionsService,
    AutocompleteApiService,
    AutocompleteItemsService,
    ScrollService,
    SavedOfferService,
    //OffersService,
    LayoutService,
    //ThirdStepService,
    //ContractPreviewService,
    InsurenceService,
    ApiOffersService,
    ModalNotifyService,
    DeviceService,
    ConfirmChangesGuardGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'sk-SK' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private storeFacade: StoreFacade) {
    this.storeFacade.dispatch(
      loadAutocompleteItems({ itemsCode: AutocompleteItemsEnum.VehicleBrand })
    );
    this.storeFacade.dispatch(
      loadAutocompleteItems({
        itemsCode: AutocompleteItemsEnum.VehicleSalesName
      })
    );
    this.storeFacade.dispatch(
      loadAutocompleteItems({ itemsCode: AutocompleteItemsEnum.City })
    );
    this.storeFacade.dispatch(
      loadAutocompleteItems({ itemsCode: AutocompleteItemsEnum.ZipCode })
    );
  }
}
