import {ApplicationRef, Component, NgZone, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {Gtag} from 'angular-gtag';
import {OfflineComponent} from 'app/components/offline/offline.component';

import {ContactComponent} from './pages/contact/contact.component';
import {CategoriesService, DeviceService} from './services';
import {InsurenceService} from './services/insurence.service';
import {SavedOfferService} from './services/saved-offer.service';
import {ScrollService} from './services/scroll.service';
import { Subscription } from 'rxjs';

declare var window: any;
declare var Offline: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  categories = [];
  showMenu;
  currentCategory;
  clickedCategoryId = 0;
  mobileMenuHidden = false;
  insurenceCompanies: any[] = [];
  connectionIntervalChecker;
  testEnvironment = false;
  isHome = false;

  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private categoriesService: CategoriesService,
    private scrollService: ScrollService,
    private insurenceService: InsurenceService,
    gtag: Gtag,
    private applicationRef: ApplicationRef,
    private dialog: MatDialog,
    private _ngZone: NgZone,
    private deviceService: DeviceService
  ) {

    // this.dialog.open(FillRequiredFieldsComponent, {});

    if (window.location.hostname.indexOf('test.poistuj') > -1) {
      this.testEnvironment = true;
    }

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome = false;
        if (event.url === '/') {
          this.isHome = true;
        }
        setTimeout(() => {
          this.scrollService.scrollToTop();
        }, 250);
      }
    });

    this.subs.push(this.insurenceService.loadInsurenceCompanies().subscribe(companies => {
      this.insurenceCompanies = companies.filter(comp => comp.imageHq != '-' && comp.imageHq != null);
    }));
    this.subs.push(categoriesService.categoriesChange.subscribe(cats => this.categories = cats));
    this.subs.push(categoriesService.selectedCategoryChange.subscribe(selectedCategory => {
      if (selectedCategory) {
        this.clickedCategoryId = selectedCategory.id;
      }
    }));
    this.subs.push(this.categoriesService.showProductsSubmenuChange.subscribe(show => {
      this.showMenu = show;
    }));
    categoriesService.load();

    let dialogRef;
    const online = true;

    Offline.options = {
        // to check the connection status immediatly on page load.
        checkOnLoad: true,
        // to monitor AJAX requests to check connection.
        interceptRequests: true,
        // to automatically retest periodically when the connection is down (set to false to disable).
        reconnect: true,
        // to store and attempt to remake requests which failed while the connection was down.
        requests: false,
        checks: {xhr: {url: '/assets/icon/favicon.ico'}}
    };



    Offline.on('confirmed-up', () => {
        // this.emitIsOnline(true);
        if (dialogRef) {
          this._ngZone.run(() => {
            dialogRef.close();
          });
        }
    });

    Offline.on('confirmed-down', () => {
        // this.emitIsOnline(false);
        if (typeof dialogRef === 'undefined') {
          dialogRef = dialog.open(OfflineComponent);
          const sub = dialogRef.afterClosed().subscribe(result => {
            dialogRef = undefined;
            sub.unsubscribe();
          });
        }
    });

    this.applicationRef.isStable.subscribe((s) => {
        if (s) {
            if (!this.connectionIntervalChecker) {
                this.connectionIntervalChecker = setInterval(() => {
                    if (online) {
                        Offline.check();
                    }
                }, 3000);
            }
        }
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub)=>sub.unsubscribe());
  }

  openContact() {
    if (this.deviceService.isMobile()) {
      this.dialog.open(ContactComponent, {
        panelClass: 'grey-modal',
        width: '100%',
        height: '100%'
      });
    } else {
      this.dialog.open(ContactComponent, {
        panelClass: 'grey-modal'
      });
    }

  }

  categoryClick(category) {
    if (!this.mobileMenuHidden) {
        this.mobileMenuHidden = true;
    }
    this.router.navigate(['kat', category.slug]);
  }

  openMobileMenu() {
    this.mobileMenuHidden = !this.mobileMenuHidden;
  }


}
