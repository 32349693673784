import { AvailableApi } from './available-api';

export enum DebugPriceOfferStatus {
    loadingFromServer = 'loadingFromServer',
    offerReveived = 'offerReveived',
    error = 'error',
}

export type DebugPriceOffer = {
    availableApi: AvailableApi;
    status: DebugPriceOfferStatus;
}
