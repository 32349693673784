import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-category-modal',
  templateUrl: './choose-category-modal.component.html',
  styleUrls: ['./choose-category-modal.component.scss']
})
export class ChooseCategoryModalComponent {

  constructor(public dialogRef: MatDialogRef<ChooseCategoryModalComponent>) { }
  
  close() {
    this.dialogRef.close();
  }

}
