import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiService {
    constructor (private http: HttpClient) {}

    getHeaders() {
    }

    getOptions() {
        return {};
    }

    get(url): Observable<any> {        
        return this.http.get(environment.API_URL + url, this.getOptions());
    }

    post(url, data): Observable<any> {
        return this.http.post(environment.API_URL + url, data, this.getOptions());     
    }

    put(url, data): Observable<any> {
        return this.http.put(environment.API_URL + url, data, this.getOptions());     
    }

    delete(url): Observable<any> {
        return this.http.delete(environment.API_URL + url, this.getOptions());     
    }
}