import { Injectable } from '@angular/core';
import { AutocompleteApiService } from './autocomplete-api.service';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

@Injectable()
export class AutocompleteItemsService {
    private itemsLoadingByType: any = {};    

    private items: BehaviorSubject<any> = new BehaviorSubject({});
    itemsChange: Observable<any>;

    constructor(private autocompleteApiService: AutocompleteApiService) {
        this.itemsChange = this.items.asObservable();
    }

    getItems() {
        return this.items.getValue();
    }

    loadItemsOfType( field ) {

        let recordType = field.code;

        if (field.code.indexOf('city')>-1 || field.valueType=='city') {
          recordType = 'city';
        }

        if (field.code.indexOf('zip-code')>-1) {
          recordType = 'zip-code';
        }
        
        if (!this.itemsLoadingByType.hasOwnProperty(recordType)) {            
            this.itemsLoadingByType[recordType] = true;            
            this.autocompleteApiService.getItems( recordType )
            .subscribe(items => {
                let arr = [];
                for(let key in items){
                    if(items.hasOwnProperty(key)){
                        arr.push(items[key]);
                    }
                }
                let newItems = this.items.getValue();
                if (recordType=='vehicle-sales-name' || recordType=='vehicle-brand') {
                    newItems[recordType]=arr.sort((obj1, obj2) => {
                        return obj1.title.toUpperCase().localeCompare(obj2.title.toUpperCase());
                   });
                } else {
                    newItems[recordType]=arr;
                }                
                this.items.next(newItems);
                //field.items = items;
            }); 
        }

        return recordType;

    }

}