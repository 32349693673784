import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InsurenceService } from '../../services/insurence.service';
import { LayoutService } from '../../services/layout.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-insurence-page',
  templateUrl: './insurence-page.component.html',
  styleUrls: ['./insurence-page.component.scss']
})
export class InsurencePageComponent implements OnInit {
  paramSub;
  insCompany;

  constructor(
    private route: ActivatedRoute,
    private insurenceService: InsurenceService,
    private layoutService: LayoutService,
    private sanitized: DomSanitizer
  ) { 
    layoutService.setBodyClasses(['insurence-page']);
  }

  ngOnInit() {
    this.paramSub = this.route.params.subscribe(params => {
       let id = +params['id']; // (+) converts string 'id' to a number
       this.insurenceService.load(id).subscribe(data=>{         
         data.content = this.sanitized.bypassSecurityTrustHtml(data.content);
         this.insCompany = data;
       }); 
    });
  }

}
