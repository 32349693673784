import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SavedOfferService } from './saved-offer.service';
import { ApiOffersService } from './api-offers.service';
import { map } from 'rxjs/operators';

@Injectable()
export class OffersService {

    private chosenOffer: BehaviorSubject<any> = new BehaviorSubject(null);
    chosenOfferChange: Observable<any>;

    private offers: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    offersChange: Observable<any>;

    constructor(
        private apiService: ApiService,
        private savedOfferService: SavedOfferService,
        private apiOffersService: ApiOffersService
    ) {
        this.offersChange = this.offers.asObservable();
        this.chosenOfferChange = this.chosenOffer.asObservable();
    }

    getOverview(product) {
        var result = '';
        var offer = this.chosenOffer.getValue();

        if (offer) {
        
            result += '<span>'+offer.title+',</span>';

            if (offer.payFrequency=='yearly') {
                result += '<span class="offer-overview-price">' + offer.price + ' € ročne</span>';
            }

            if (offer.payFrequency=='half') {
                result += '<span class="offer-overview-price">' + offer.priceHalf + ' € polročne</span>';
            }

            if (offer.payFrequency=='quarterly') {
                result += '<span class="offer-overview-price">' + offer.priceThird + ' € štvrťročne</span>';
            } 
                    
            /*
            angular.forEach(offer.services, function(service, key) {
                result += '<span class="overview-offer-service"><img src="admin/web/uploads/productservices/icons/'+service.icon+'"></span>';
            });
            */

            return result;
        } else {
           // console.log('NO OFFER IN GETOVERVIEW() METHOD:');
            return '';
        }
    }

    getChosenOffer() {
        return this.chosenOffer.getValue();
    }

    choseOffer(offer) {
        this.chosenOffer.next(offer);
    }    

    loadServerOffers(id, firstFormFields) {
        this.offers.next([]);
        this.apiService.post('/getproductoffers/'+id, { fields: firstFormFields })
                .subscribe(data=>{
                    this.savedOfferService.setSavedOffer(data.savedOffer);
                    let offers = data.offers.map(offer=>{
                        offer.priceStatus = 'OK';
                        return offer
                    }).sort(this.compareOffersForSortine);
                    this.offers.next(offers);
                    this.savedOfferService.setSavedOffer(data.savedOffer);
                    this.apiOffersService.setSavedOffer(data.savedOffer);
                });        
    }


    loadApiOffers(product, category, formData) {                
        this.apiOffersService.loadOffers(product, category, formData);
    }

    compareOffersForSortine(a,b) {
        if ((parseInt(a.price) < parseInt(b.price)) && a.price !== 0)
            return -1;
        else if ((parseInt(a.price) > parseInt(b.price)) || a.price == 0)
            return 1;
        else
            return 0;
    }
}